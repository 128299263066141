import { db } from "./firebaseConfig";
import { doc, updateDoc } from "firebase/firestore";


const actualizarCliente = async ({
    idDoc,
    nombre,
    correo,
    telefono,
    direccion,
    estado, 
    numdoc ,
    cfiva,
    razonSocial,
    tipoDeCliente
}) => {
    const documento = doc(db, "clientes", idDoc);

    return await updateDoc(documento,{
        nombre:nombre,
        correo:correo,
        telefono:telefono,
        direccion:direccion,
        estado:estado,
        documento:numdoc,
        cfiva:cfiva,
        razonSocial:razonSocial,
        tipoDeCliente:tipoDeCliente
    });
}

export default actualizarCliente;
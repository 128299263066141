import styled from "styled-components";




const ItemProducto = styled.tr`
 width: 20%;
td:nth-child(5) {
  width: 20%;
  text-align:center ;
}

    td{
        text-transform: capitalize;
        padding: 12px 15px;
        
        div{
            width: 100%;
            display: flex;
            flex-direction:row;
            flex-wrap:nowrap;
            justify-content:center;
            align-items:center;
            margin:auto;
            box-sizing: border-box;
            input{
                width: 30%;
                border-radius: 3px;
                height:18px ;
                border:1px solid var(--primary);
                background: transparent;
            }
        }
        button{
        width: 22px;
        height: 22px;
        padding:0.2em;
        background:transparent;
        color:var(--primary);
        border:1px solid var(--primary);
        border-radius:3px;
        margin:0.2em;
        cursor:pointer;
        :hover{
            background: var(--primary);
            color:#fff;
            
        }
        svg{
            width: 60%;
        }
        
        }
    }

`

export { ItemProducto }


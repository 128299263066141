import { DivTotales,ThumbItemTotal } from "./style";
import { contextCaja } from "../../context/contextCaja";
import { useContext } from "react";

const DatosTotales = () => {

       const {
        subTotal,
        total,
        descuento,
        setDescuento
    }=useContext(contextCaja)

    const aplicarDescuento = (d) => {
        const newDescuento = parseFloat(d);
        if (newDescuento !== '' && newDescuento > 0 && newDescuento <= 100) {
            setDescuento(newDescuento)
        } else {
            setDescuento(0)
        }
    }
    return ( 
        <DivTotales>
            <ThumbItemTotal>
                <label>Subtotal</label>
                <p>${subTotal}</p>
            </ThumbItemTotal>
            
            <ThumbItemTotal>
                <label>Descuento (%)</label>
                <input
                type="number"
                value={descuento}
                onChange={(e)=>{aplicarDescuento(e.target.value)}}
                placeholder="Descuento"
                ></input>
            </ThumbItemTotal>
            <ThumbItemTotal>
                <h5>TOTAL</h5>
                <p>${total}</p>
            </ThumbItemTotal>
        </DivTotales>
     );
}
 
export default DatosTotales;
import { db } from "../firebase/firebaseConfig";
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";



const useObtenerProductoPorId = (id, viewModal) => {
    const [productoById, setProductoById] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {

        if (id !== '') {
            const productoPorId = async () => {
                const documento = await getDoc(doc(db, 'productos', id));
                if (documento.exists) {
                    setProductoById(documento)
                } else {
                    navigate('/usuarios');
                }

            }
            productoPorId();
        }
    }, [id, navigate, viewModal])

    return [productoById];
}

export default useObtenerProductoPorId;
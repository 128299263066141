import styled from 'styled-components'


const ProductStyle=styled.div`

    width: 90%;
    margin: 1em auto;
    background: #fff;
    border-radius: 15px;


`

const SelectProduct=styled.div`
    width: 40%;
    display: flex;
    flex-direction:column;
    margin-left:1em;
    h5{
        font-weight:600;
        margin-bottom:0em;
    }
    select{
        width: 100%;
        height: 30px;
    }
` 

const DivTableProduct=styled.div` 
    width: 90%;
    margin: 1em auto;
    padding: 0 0 2em 0;

`
const DivBuscador=styled.div` 
    width: 50%;
    margin: 2em auto 0em auto;
    
`
const DivProporcion=styled.div`
    width: 60%;
`
const DivAvisoCantidadEnAlmacen=styled.div`
    width: 90%;
    padding: 0.5em;
    text-align: center;
    background: var(--primary);
    border-radius: 5px ;
    margin: 1em auto;
    p{
        font-weight: bold;
        color: #fff;
        margin:0.1em 0 ;
    }
`
const PDFButton=styled.button`
background: transparent;
border: none;
outline:none ;
cursor: pointer;
`
export {ProductStyle,SelectProduct,DivTableProduct,DivBuscador,DivProporcion,DivAvisoCantidadEnAlmacen,PDFButton}
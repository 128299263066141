import { db } from "./firebaseConfig";
//import { collection, addDoc } from "firebase/firestore";

import { writeBatch, doc } from "firebase/firestore";
// Get a new write batch
const crearOrden = async ({
  cliente,
  productos,
  fecha,
  formDePago,
  rangoHorario,
  subTotal,
  descuento,
  total,
}) => {
  const batch = writeBatch(db);
  // agregar un producto nuevo
  const nycRef = doc(db, "ordenes", crypto.randomUUID());
  batch.set(nycRef, {
    cliente: cliente,
    productos: productos,
    fecha: fecha,
    formDePago: formDePago,
    rangoHorario: rangoHorario,
    subTotal: subTotal,
    descuento: descuento,
    total: total,
    estado: "pendiente",
    fechaUnix: new Date().getTime(),
  });
  for (let i = 0; i < productos.length; i++) {
    let sfRef = doc(db, "productos", productos[i].idProducto);
    batch.update(sfRef, {
      cant: parseInt(productos[i].stock) - parseInt(productos[i].cant),
    });
  }
  return await batch.commit();
};

export default crearOrden;

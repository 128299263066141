import { useState, useEffect } from "react";
import { db } from "../firebase/firebaseConfig";
import { collection, onSnapshot, query  } from "firebase/firestore";




const useObtenerClientes = () => {

    const [ clientes, setStock]=useState([]);

    useEffect(()=>{
        const consulta=query(
            collection(db, 'clientes')
        )
       
            const unsub = onSnapshot(consulta, (snapshot) => {
                    const dataSnapShot=snapshot.docs.map((cliente)=>{
                       
                        return [
                        
                                cliente.id,
                                cliente.data().nombre,
                                cliente.data().razonSocial,
                                cliente.data().documento,
                                cliente.data().cfiva,
                              cliente.data().correo,
                               cliente.data().telefono,
                            cliente.data().direccion,
                            cliente.data().estado,
                            cliente.data().tipoDeCliente
                        ]
                    });
                    setStock(dataSnapShot) ;
            });
          
            
            return unsub;
           
    },[])
    return [clientes];
}
 
export default useObtenerClientes;
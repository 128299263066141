import Row from "../../../componentes/Row";
import Column from "../../../componentes/column";
import Buscador from "../../../componentes/buscador";
import Modal from "../../../componentes/modal";
import { DivTableClientes } from "../../clientes/style";
import Tabla from "../../../componentes/tabla";
import useObtenerProductos from "../../../hooks/useObtenerProductos";
import { contextCaja } from "../../../context/contextCaja";
import { useContext, useEffect, useState } from "react";

const ModalProductosCaja = ({ viewModal, setViewModal }) => {
  const [productos] = useObtenerProductos();
  const { agregarProductoCaja } = useContext(contextCaja);
  const [dataTabla, setDataTabla] = useState([]);
  const [dataTablaStatic, setDataTablaStactic] = useState([]);

  const seleccionarProducto = (idProducto) => {
    const [productoSeleccionado] = productos.filter((producto) => {
      return producto[0] === idProducto;
    });

    agregarProductoCaja({
      idProducto: productoSeleccionado[0],
      stock: productoSeleccionado[2],
      cod: productoSeleccionado[8],
      nombre: productoSeleccionado[1],
      precioBase: productoSeleccionado[9],
      precioCliente: productoSeleccionado[10],
      precioCorralon: productoSeleccionado[11],
      precioFerreteria: productoSeleccionado[12],
      cant: 1,
    });
  };
  useEffect(() => {
    setDataTabla(productos);
    setDataTablaStactic(productos);
  }, [viewModal, productos]);

  return (
    <>
      <Modal
        titulo={"Agregar Productos"}
        estadoModal={viewModal}
        cambiarEstado={setViewModal}
        tamano={80}
      >
        <Row>
          <Column w={50}>
            <Buscador
              setDataTabla={setDataTabla}
              dataTablaStatic={dataTablaStatic}
            ></Buscador>
          </Column>
        </Row>

        <Row>
          <DivTableClientes>
            {dataTabla.length > 0 ? (
              <Tabla>
                <>
                  <thead>
                    <tr>
                      <th>Nombre </th>
                      <th>Precio </th>
                      <th>Cantidad Disponible </th>
                      <th>Acciones </th>
                    </tr>
                  </thead>
                  <tbody>

                    {dataTabla.map((producto) => {
                      return (
                        <tr key={producto[0]}>
                          <td>{producto[1]} </td>
                          <td>{producto[9]} </td>
                          <td>{producto[2]} </td>
                          <td>
                            <div>
                              {producto[2] > 0 ? (
                                <button
                                  onClick={() => {
                                    seleccionarProducto(producto[0]);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                  </svg>
                                </button>
                              ) : (
                                <div
                                  style={{
                                    background: "#f3b6b6",
                                    padding: "0.5em",
                                    borderRadius: "0.5em",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Sin Stock
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </>
              </Tabla>
            ) : (
              <></>
            )}
          </DivTableClientes>
        </Row>
      </Modal>
    </>
  );
};

export default ModalProductosCaja;


import { HeaderStyles } from "./style";
import { auth } from "../../firebase/firebaseConfig";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const Header = () => {
    const navigate=useNavigate();

    const cerrarSesion=async()=>{
        try {
            await  signOut(auth);
            navigate('/');
            
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <HeaderStyles>
            <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 218.95 62.85">
                <g>
                    <path d="M204.76,427q-4.41,0-6.59-2.18t-2.19-6.6V403.57a8.84,8.84,0,0,1,2.21-6.44,8.38,8.38,0,0,1,6.27-2.25h20.73v8.69H206.08v14.65h19.07V427Z" transform="translate(-195.98 -364.15)" fill="#444645" />
                    <path d="M228.61,427V394.88H255.4v8.69H238.67v3.69h16.65v7.27H238.67v3.69h16.61V427Z" transform="translate(-195.98 -364.15)" fill="#444645" />
                    <path d="M277.72,416.38c.13-1,.27-1.85.42-2.69s.32-1.64.48-2.4l3.87-16.41h13.9V427h-9.56V411.13c0-1.08.05-2.18.15-3.29s.24-2.22.42-3.33h-.32L281.67,427h-7.86l-5.45-22.49h-.28q.27,1.67.42,3.33c.1,1.11.15,2.21.15,3.29V427H259.1V394.88H273l3.86,16.41c.15.63.3,1.35.43,2.15S277.57,415.21,277.72,416.38Z" transform="translate(-195.98 -364.15)" fill="#444645" />
                    <path d="M300.17,401.51a6.28,6.28,0,0,1,1.91-4.86,7.45,7.45,0,0,1,5.27-1.77H324a7.45,7.45,0,0,1,5.27,1.77,6.28,6.28,0,0,1,1.92,4.86V427h-10V414.07h-11V427h-10Zm10,6.05h11v-4h-11Z" transform="translate(-195.98 -364.15)" fill="#444645" />
                    <path d="M343.61,427q-4.41,0-6.6-2.18t-2.18-6.6V403.57a8.8,8.8,0,0,1,2.21-6.44,8.37,8.37,0,0,1,6.27-2.25H364v8.69H344.93v14.65H364V427Z" transform="translate(-195.98 -364.15)" fill="#444645" />
                    <path d="M397.61,418.35q0,4.31-1.95,6.48c-1.31,1.45-3.25,2.17-5.84,2.17H375c-2.61,0-4.56-.72-5.87-2.17s-2-3.61-2-6.48V403.57q0-4.41,1.93-6.55c1.29-1.43,3.25-2.14,5.9-2.14h14.78c2.66,0,4.62.7,5.89,2.12s1.9,3.6,1.9,6.57Zm-20.35-.13h10.33V403.57H377.26Z" transform="translate(-195.98 -364.15)" fill="#444645" />
                </g>
                <rect x="362.59" y="373.08" width="29.1" height="9.29" transform="translate(-352.61 13.16) rotate(-45)" fill="#70ad46" />
                <rect x="374.48" y="378.19" width="43.54" height="9.28" transform="translate(209.76 569.57) rotate(-135)" fill="#70ad46" />
                <path d="M404.11,372.26v10.57s-4.72-3.57-5.15-6.86a12.94,12.94,0,0,1,0-4.71S402.39,370.4,404.11,372.26Z" transform="translate(-195.98 -364.15)" fill="#70ad46" />
            </svg>
            <button onClick={cerrarSesion}>Cerrar Sesión</button>
        </HeaderStyles>
    );
}

export default Header;

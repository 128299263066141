import { OrdenesStyle, ThumbOrdenes, DateContainer, ButtonDate } from './style';
import BtnList from '../../componentes/btnList';
import { useState } from 'react';
import System from '../../componentes/sistema';
import useObtenerOrdenes from '../../hooks/useObtenerOrdenes';
import ModalEditarOrden from './modalEditarOrden';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import esLocale from 'date-fns/locale/es';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import Row from '../../componentes/Row';
// import AgregarDato from "../../firebase/agregarDato";
// import Button from "../../componentes/button";

const Ordenes = () => {
  const [viewModalEditar, setViewModalEditar] = useState(false);
  const [idEditar, SetidEditar] = useState('');
  const [queryByDay, setQueryByDay] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const { ordenes } = useObtenerOrdenes(startDate, endDate, queryByDay);


  const modalEditar = (id) => {
    SetidEditar(id);
    setViewModalEditar(true);
  };

  return (
    <System>
      <ModalEditarOrden
        viewModal={viewModalEditar}
        setViewModal={setViewModalEditar}
        idEditar={idEditar}
      ></ModalEditarOrden>

      <OrdenesStyle>
        <Row justifyContent='space-between'>
          <div>
            <DateContainer>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker value={startDate} onChange={setStartDate} />
              </MuiPickersUtilsProvider>
              {
                !queryByDay &&
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <DatePicker value={endDate} onChange={setEndDate} />
                </MuiPickersUtilsProvider>
              }
            </DateContainer>
            <ButtonDate onClick={() => { setQueryByDay(!queryByDay) }}>
              Consultar Por {!queryByDay ? 'Día' : 'Rango'}
            </ButtonDate>

          </div>
          <span style={{ marginRight: '1.5em' }}>
            {ordenes.length} Ordenes
          </span>

        </Row>
        <ThumbOrdenes>
          {ordenes.length > 0 ? (
            ordenes.map((orden) => {
              return (
                <BtnList
                  onClick={() => {
                    modalEditar(orden.idOrden);
                  }}
                  key={orden.idOrden}
                >
                  <span>{`${orden.fechaOrden.getDate()}/${orden.fechaOrden.getMonth() + 1
                    }/${orden.fechaOrden.getFullYear()}`}</span>
                  <span>{orden.cliente.nombre}</span>
                  <span>{orden.estado}</span>
                </BtnList>
              );
            })
          ) : (
            <></>
          )}
        </ThumbOrdenes>
      </OrdenesStyle>
    </System>
  );
};

export default Ordenes;

import styled from "styled-components";

const DivPlayaStyles=styled.div`

    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap:nowrap;
    justify-content: space-around;
    align-items: flex-start;
    margin: 2em auto;
    
`
const CardPlaya=styled.div`
width: 27%;
padding: 15px;

border-radius: 15px;
background: #fff;
`
const HeaderCardPlaya=styled.div`
width: 100%;

padding: 1em 0;
display: flex;
justify-content: center;
align-items: center;
font-weight: bold;

color:var(--primary);
`
const BodyCardPlaya=styled.div`
width: 100%;
max-height: 500px;
overflow-y: auto;
`

const DivItemPlaya=styled.button`
    width: 90%;
    display: block;
    margin: 5px auto;
    border: none ;
    padding: 15px;
    background: var(--gris-border-btn);
    border-radius: 5px;
    font-weight: bold;
    font-family: inherit;
    color: var(--color-text);
    transition: transform 0.2s ease ;
    cursor:pointer;
    :hover{
        background: var(--gris);
        transform: scale(1.05);
    }

`
const DateContainer=styled.div`
width: 20%;
margin-left: 1em;
padding: 1em;
`
export {DivPlayaStyles,
    CardPlaya,
    HeaderCardPlaya,
    BodyCardPlaya,DivItemPlaya,DateContainer}
import React, { useEffect, useState } from 'react';

import { useCallback } from 'react';
import useGETFormaDePago from '../hooks/useGETFormasDePpago';
import useGETRangoHorario from '../hooks/useGETRangoHorario';
import useObtenerProductos from '../hooks/useObtenerProductos';
const contextCaja = React.createContext();

const ProviderCaja = ({ children }) => {
  const [cliente, setCliente] = useState({
    id: '',
    nombre: '',
    correo: '',
    telefono: '',
    direccion: '',
    tipo: '',
    cvIVA: '',
    descuento: 0,
  });
  const [productos, setProductos] = useState([]);
  const [fecha, setFecha] = useState(new Date());
  const [formDePago, setFormaDePago] = useState('tarjeta de credito');
  const [rangoHorario, setRangoHorario] = useState('mañana');
  const [subTotal, setSubtotal] = useState(0);
  const [descuento, setDescuento] = useState(0);
  const [total, setTotal] = useState(0);

  const [formasDePago] = useGETFormaDePago();
  const [rangosHorarios] = useGETRangoHorario();
  const [productosBD = productos] = useObtenerProductos();

  useEffect(() => {
    let subTotalTemp = 0;
    for (let i = 0; i < productos.length; i++) {
      subTotalTemp = subTotalTemp + productos[i].precio * productos[i].cant;
    }
    setSubtotal(subTotalTemp.toFixed(2));
    setTotal(
      (subTotalTemp - subTotalTemp * (Number(descuento) / 100)).toFixed(2)
    );
  }, [productos, descuento]);

  useEffect(() => {
    if (formasDePago.length > 0 && rangosHorarios.length > 0) {
      setFormaDePago(formasDePago[0].nombre);
      setRangoHorario(rangosHorarios[0].nombre);
    }
  }, [formasDePago, rangosHorarios]);

  const verificarTipodePrecio = (newCliente) => {
    let tipoDePrecio = null;
    switch (newCliente.tipoDePrecio) {
      case 'precioBase':
        tipoDePrecio = 9;
        break;
      case 'precioCliente':
        tipoDePrecio = 10;
        break;
      case 'precioCorralon':
        tipoDePrecio = 11;
        break;
      case 'precioFerreteria':
        tipoDePrecio = 12;
        break;

      default:
        tipoDePrecio = 9;
        break;
    }

    return tipoDePrecio;
  };

  const agregarCliente = (newCliente) => {
    setCliente(newCliente);
    const tipodePrecio = verificarTipodePrecio(newCliente);

    if (productos.length > 0) {
      //CLONACION DE ARRAY (NO POR REFERENCIA)
      let copiaProductos = JSON.parse(JSON.stringify([...productos]));

      productosBD.forEach((proBD) => {
        copiaProductos.forEach((proCopia) => {
          if (proBD[0] === proCopia.idProducto) {
            proCopia.precio = Number(proBD[tipodePrecio]);
          }
        });
      });

      setProductos(copiaProductos);
    }
  };
  const eliminarProductoCaja = (id) => {
    const arrayProductosBorrados = productos.filter((producto) => {
      return producto.idProducto !== id;
    });
    setProductos(arrayProductosBorrados);
  };
  const agregarProductoCaja = (newProducto) => {
    //verificacion de producto repetido
    const indexRepetido = productos.findIndex((proIndex) => {
      return proIndex.idProducto === newProducto.idProducto;
    });
    if (indexRepetido === -1) {
      //ajuste de precio segun tipo de cliente
      if (cliente.id !== '') {
        // const NewArrayProducts=productos.map((productoNew)=>{
        //     return {...productoNew,precio:Number(productoNew.precio) - Number(productoNew.precio)*(Number(cliente.descuento)/100)}
        // })

        const newProductoNuevoPrecio = {
          ...newProducto,
          precio: Number(newProducto[cliente.tipoDePrecio]),
        };

        setProductos([...productos, newProductoNuevoPrecio]);
      } else {
        setProductos([...productos, newProducto]);
      }
      //-------------------------
    } else {
      //--------entonces aumenta
    }
  };
  const setCantidadProducto = useCallback(
    (idProducto, newCant) => {
      const index = productos.findIndex((producto) => {
        return producto.idProducto === idProducto;
      });
      let copiaProductos = [...productos];
      copiaProductos[index].cant = newCant;
      setProductos(copiaProductos);
    },
    [productos]
  );

  const resetCaja = () => {
    setCliente({
      id: '',
      nombre: '',
      correo: '',
      telefono: '',
      direccion: '',
      tipo: '',
      cvIVA: '',
      descuento: 0,
    });
    setProductos([]);
    setFecha(new Date());
    setFormaDePago('');
    setRangoHorario('');
    setSubtotal(0);
    setDescuento(0);
    setTotal(0);
  };

  return (
    <contextCaja.Provider
      value={{
        cliente,
        productos,
        fecha,
        formDePago,
        rangoHorario,
        subTotal,
        descuento,
        total,
        agregarCliente,
        setFecha,
        setFormaDePago,
        setRangoHorario,
        setDescuento,
        agregarProductoCaja,
        eliminarProductoCaja,
        setCantidadProducto,
        resetCaja,
      }}
    >
      {children}
    </contextCaja.Provider>
  );
};

export { contextCaja, ProviderCaja };

import { DivDatosOrden, SelectOrden } from "./style";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useContext, useState } from "react";
import useGETFormaDePago from "../../hooks/useGETFormasDePpago";
import useGETRangoHorario from "../../hooks/useGETRangoHorario";
import { contextCaja } from "../../context/contextCaja";
import '../../../node_modules/react-day-picker/dist/style.css'
import esLocale from 'date-fns/locale/es'
import DateFnsUtils from '@date-io/date-fns'; // choose your lib

const DatosOrden = () => {
    const [formasDePago] = useGETFormaDePago();
    const [rangosHorarios] = useGETRangoHorario();

    const {
        formaDePago,
        setFormaDePago,
        rangoHorario,
        setRangoHorario,
        fecha,
        setFecha
    } = useContext(contextCaja)

    return (
        <DivDatosOrden>

            <SelectOrden>
                <h5>Fecha de Entrega</h5>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <DatePicker value={fecha} onChange={setFecha} />

                </MuiPickersUtilsProvider>
               

            </SelectOrden>


            <SelectOrden>
                <h5>Forma de Pago</h5>
                <select
                    value={formaDePago}
                    onChange={(e) => { setFormaDePago(e.target.value) }}
                >
                    {
                        formasDePago.map((forma) => {
                            return <option key={forma.id} value={forma.nombre}>{forma.nombre}</option>
                        })
                    }


                </select>
            </SelectOrden>
            <SelectOrden>
                <h5>Rango Horario</h5>
                <select
                    value={(rangoHorario)}
                    onChange={(e) => { setRangoHorario(e.target.value) }}
                >
                    {
                        rangosHorarios.map((rango) => {
                            return <option key={rango.id} value={rango.nombre}>{rango.nombre}</option>
                        })
                    }
                </select>
            </SelectOrden>
        </DivDatosOrden>

    );
}

export default DatosOrden;
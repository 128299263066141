import { useState, useEffect } from "react";
import { db } from "../firebase/firebaseConfig";
import { collection, onSnapshot, query  } from "firebase/firestore";




const useGETRangoHorario = () => {

    const [ rangoHorario, setRangoHorario]=useState([]);

    useEffect(()=>{
        const consulta=query(
            collection(db, 'rangoHorarioEntrega')
        )
       
            const unsub = onSnapshot(consulta, (snapshot) => {
                    const dataSnapShot=snapshot.docs.map((rangoHorario)=>{
                       
                        return {
                        
                                id:rangoHorario.id,
                                nombre:rangoHorario.data().nombre,
                            
                             
                        }
                    });
                    setRangoHorario(dataSnapShot) ;
            });
          
            
            return unsub;
           
    },[])
    return [rangoHorario];
}
 
export default useGETRangoHorario;

import { ContentModalOrdenes, DetalleOrden } from './style'
import Modal from '../../../componentes/modal';
import Row from '../../../componentes/Row';
import Column from '../../../componentes/column';
import useObtenerOrdenesPorId from '../../../hooks/useObtenerOrdenporID';
import { DivDatosCliente } from '../../../componentes/datosCliente/style';
import { useEffect, useState, useRef } from 'react';
import Tabla from '../../../componentes/tabla';
import Button from '../../../componentes/button';
import actualizarOrdenes from '../../../firebase/actualizarOrdenes';
import Alert from '../../../componentes/alert';
import ReactToPrint from 'react-to-print';


const ModalEditarOrden = ({ viewModal, setViewModal, idEditar }) => {
    const [orden] = useObtenerOrdenesPorId(idEditar)
    const [Nombre, setNombre] = useState('')
    const [Correo, setCorreo] = useState('')
    const [Telefono, setTelefono] = useState('')
    const [Direccion, setDireccion] = useState('')
    const [CFIVA, setCFIVA] = useState('')
    const [productos, setProductos] = useState([])
    const [fecha, setFecha] = useState('')
    const [fechaOrden, setFechaOrden] = useState('')
    const [formDePago, setFormaDePago] = useState('')
    const [rangoHorario, setRangoHorario] = useState('')
    const [subTotal, setSubtotal] = useState('')
    const [descuento, setDescuento] = useState('')
    const [total, setTotal] = useState('')
    const [Estado, setEstado] = useState('')
    const [estadoAlert, setEstadoAlert] = useState(false);
    const [mensajeAlert, setMensajeAlert] = useState({});
    const componentRef = useRef();


    useEffect(() => {
        if (orden && orden.length !== 0) {
            console.log(orden)
            setNombre(orden.data().cliente.nombre)
            setCorreo(orden.data().cliente.correo)
            setTelefono(orden.data().cliente.telefono)
            setDireccion(orden.data().cliente.direccion)
            setCFIVA(orden.data().cliente.cfiva)
            setProductos(orden.data().productos)
            setFecha(`${orden.data().fecha.toDate().getDate()}/${orden.data().fecha.toDate().getMonth() + 1}/${orden.data().fecha.toDate().getFullYear()}`)
            const date = new Date(orden.data().fechaUnix)
            setFechaOrden(`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`)
            setFormaDePago(orden.data().formDePago)
            setRangoHorario(orden.data().rangoHorario)
            setSubtotal(orden.data().subTotal)
            setDescuento(orden.data().descuento)
            setTotal(orden.data().total)
            setEstado(orden.data().estado)
        }

    }, [idEditar, orden])

    const UpdateOrdenes = async (estadoValor) => {

        try {


            actualizarOrdenes({
                idDoc: idEditar,
                estado: estadoValor,
                productos

            });
            setEstadoAlert(true);
            setMensajeAlert({
                mensajeAlert: 'La orden se actualizó correctamente',
                type: 'success'
            })
            setViewModal(false);

        } catch (err) {
            setEstadoAlert(true);

            let mensaje;
            switch (err.code) {
                default:
                    mensaje = "Ocurrió un error"
                    break;
            }
            setMensajeAlert({
                mensajeAlert: mensaje,
                type: 'error'
            })
        }
    }

    return (
        <>

            <Alert
                mensajeAlert={mensajeAlert.mensajeAlert}
                type={mensajeAlert.type}
                estadoAlert={estadoAlert}
                setEstadoAlert={setEstadoAlert}

            ></Alert>
            <Modal titulo={Nombre} estadoModal={viewModal} cambiarEstado={setViewModal} tamano={80}>
                <Row justifyContent='end'>
                    <Column w={15} margin={0}>
                        <ReactToPrint
                            trigger={() => <Button><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-printer-fill" viewBox="0 0 16 16">
                                <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                                <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                            </svg></Button>}
                            content={() => componentRef.current}
                        />
                    </Column>
                </Row>
                <ContentModalOrdenes ref={componentRef}>
                    <Row>
                        <Column w={40}>
                            <h5>Estado</h5>
                            <select
                                value={Estado}
                                onChange={(e) => { setEstado(e.target.value) }}

                            >
                                <option value={'pendiente'} >Pendiente</option>
                                <option value={'en proceso'}>En Proceso</option>
                                <option value={'entregado'}>Entregado</option>

                            </select>
                            <h5>Datos Cliente</h5>
                            <DivDatosCliente>
                                <p><span>Nombre: </span>{Nombre}</p>
                                <p><span>Correo:</span>{Correo}</p>
                                <p><span>Teléfono:</span>{Telefono}</p>
                                <p><span>Dirección:</span>{Direccion}</p>
                                <p><span>C.F. IVA:</span>{CFIVA}</p>
                            </DivDatosCliente>
                        </Column>
                        <Column w={40}>
                            <h5>Datos Orden</h5>
                            <DivDatosCliente>
                                <p><span>Fecha de Emisión: </span>{fechaOrden}</p>
                                <p><span>Fecha de entrega: </span>{fecha}</p>
                                <p><span>Forma de Pago:</span>{formDePago}</p>
                                <p><span>Rango Horario:</span>{rangoHorario}</p>
                                <p><span>Subtotal:</span>{subTotal}</p>
                                <p><span>Descuento Adicional:</span>{descuento}</p>
                                <p><span>Total:</span>{total}</p>
                            </DivDatosCliente>
                        </Column>
                    </Row>
                    <DetalleOrden>
                        <Tabla>
                            {productos.length > 0 ?
                                <>
                                    <thead>
                                        <tr>
                                            <th>COD </th>
                                            <th>Cant </th>
                                            <th>Nombre </th>
                                            <th>Precio Unit</th>
                                            <th>Precio Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {productos.map((productElement) => {

                                            return (
                                                <tr key={productElement.idProducto}>
                                                    <td>{productElement.cod} </td>
                                                    <td>{productElement.cant}</td>
                                                    <td>{productElement.nombre}</td>
                                                    <td> {`$${productElement.precio}`}</td>
                                                    <td> {`$${Number(productElement.precio) * Number(productElement.cant)}`}</td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </>
                                : <></>}
                        </Tabla>
                    </DetalleOrden>
                </ContentModalOrdenes>
                <Row justifyContent={"end"} margin={"0 0 20px 0"}>
                    <Column w={15} margin={0}>
                        <Button variant='primaryDanger' onClick={() => { UpdateOrdenes('cancelada') }}>Cancelar Orden</Button>
                    </Column>
                    <Column w={15} margin={0}>
                        <Button onClick={() => { UpdateOrdenes(Estado) }}>Aceptar</Button>
                    </Column>
                </Row>

            </Modal>
        </>

    );
}

export default ModalEditarOrden;
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/login';
import Caja from './pages/caja';
import Ordenes from './pages/ordenes';
import Stock from './pages/stock';
import Productos from './pages/productos';
import Playa from './pages/playa';
import Ventas from './pages/ventas';
import Clientes from './pages/clientes';
import Proveedores from './pages/proveedores';
import Usuarios from './pages/usuarios';
import RutaPrivada from './componentes/rutaPrivada';
import TiposDeClientes from './pages/clientes/tiposDeClientes';
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Login />} />

        <Route
          path='/caja'
          element={
            <RutaPrivada>
              <Caja />
            </RutaPrivada>
          }
        />
        <Route
          path='/ordenes'
          element={
            <RutaPrivada>
              <Ordenes />
            </RutaPrivada>
          }
        />
        <Route
          path='/almacen'
          element={
            <RutaPrivada>
              <Stock />
            </RutaPrivada>
          }
        />
        <Route
          path='/productos'
          element={
            <RutaPrivada>
              <Productos />
            </RutaPrivada>
          }
        />
        <Route
          path='/ventas'
          element={
            <RutaPrivada>
              <Ventas />
            </RutaPrivada>
          }
        />
        <Route
          path='/playa'
          element={
            <RutaPrivada>
              <Playa />
            </RutaPrivada>
          }
        />
        <Route
          path='/clientes'
          element={
            <RutaPrivada>
              <Clientes />
            </RutaPrivada>
          }
        />
        <Route
          path='/clientes/tipos'
          element={
            <RutaPrivada>
              <TiposDeClientes />
            </RutaPrivada>
          }
        />
        <Route
          path='/proveedores'
          element={
            <RutaPrivada>
              <Proveedores />
            </RutaPrivada>
          }
        />
        <Route
          path='/usuarios'
          element={
            <RutaPrivada>
              <Usuarios />
            </RutaPrivada>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;

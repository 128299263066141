import Form from "../../../componentes/form";
import Row from "../../../componentes/Row";
import Column from "../../../componentes/column";
import Button from "../../../componentes/button";
import Modal from "../../../componentes/modal";
import Alert from "../../../componentes/alert";
import { useEffect, useState } from "react";
import useObtenerTiposDeCliente from "../../../hooks/useObtenerTiposDeClientes";
import useObtenerClientesporId from "../../../hooks/useObtenerClientesporId";
import actualizarCliente from "../../../firebase/actualizarCliente";



const ModalEditar = ({viewModalEditar, setViewModalEditar,idEditar}) => {

  const [tiposDeClientesData]=useObtenerTiposDeCliente();
  const [clientesByID]=useObtenerClientesporId(idEditar);
  const [estadoAlert, setEstadoAlert] = useState(false);
  const [mensajeAlert, setMensajeAlert] = useState({});
  const [nombre, setNombre] = useState('');
  const [razonSocial, setRazonSocial] = useState('');
  const [documento, setDocumento] = useState('');
  const [cfIva, setCfIva] = useState('');
    const [correo, setCorreo] = useState('');
    const [telefono, setTelefono] = useState('');
    const [direccion, setDireccion] = useState('');
    const [estado, setEstado] = useState('');
    const [tipoDeCliente, setTipoDeCliente] = useState('');
   
    useEffect(()=>{
   
    
    if(clientesByID && clientesByID.length!==0 ){

        setTipoDeCliente(clientesByID.data().tipoDeCliente);
        setNombre(clientesByID.data().nombre);
        setRazonSocial(clientesByID.data().razonSocial);
        setDocumento(clientesByID.data().documento);
        setCfIva(clientesByID.data().cfiva.toLowerCase());
        setCorreo(clientesByID.data().correo);
        setTelefono(clientesByID.data().telefono);
        setDireccion(clientesByID.data().direccion);
        setEstado(clientesByID.data().estado);
        
    }
    
 
 
}, [clientesByID, idEditar,tiposDeClientesData]);


const editar=async(e)=>{
    e.preventDefault();

    const expresionRegular=/[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;
        if( correo!=='' && !expresionRegular.test(correo)){
            setEstadoAlert(true);
            setMensajeAlert({
                mensajeAlert:'Correo no válido',
                type:'error'
            })
            return;
        }
  
   
    if (nombre==='' ) {
        setEstadoAlert(true);
        setMensajeAlert({
            mensajeAlert:'Nombre no puede quedar vacío',
            type:'error'
        })
       
        return;
    }

   
   
   
    try {
        await actualizarCliente({
            idDoc:idEditar,
            nombre:nombre,
            correo:correo,
            telefono:telefono,
            direccion:direccion,
            estado:estado,
            numdoc:documento,
            cfiva:cfIva,
            razonSocial:razonSocial,
            tipoDeCliente:tipoDeCliente
        })
   
        setEstadoAlert(true);
        setMensajeAlert({
            mensajeAlert:'El Proveedor se editó correctamente',
            type:'success'
        })
        setViewModalEditar(false);
        
    } catch (err) {
        setEstadoAlert(true);
        console.log(err);
       let mensaje;
        switch (err.code) {
            
            default:
                mensaje="Ocurrió un error"
                
                break;
        }
        setMensajeAlert({
            mensajeAlert:mensaje,
            type:'error'
        })
    }
}
    
    return ( 
      <>
        
      <Alert
          mensajeAlert={mensajeAlert.mensajeAlert}
          type={mensajeAlert.type}
          estadoAlert={estadoAlert}
          setEstadoAlert={setEstadoAlert}
          
          ></Alert>
      
      <Modal titulo={"Editar Cliente"} estadoModal={viewModalEditar} cambiarEstado={setViewModalEditar} tamano={40}>
      <Form onSubmit={editar}>

<Row>

    <Column w={90} margin={'auto'}>

        <label>Nombre</label>
        <input 
        type="text" 
        placeholder="Nombre"
        value={nombre}
        onChange={(e)=>{setNombre(e.target.value)}}
        ></input>

        <label>Razón Social</label>
        <input 
        type="text" 
        placeholder="Razón Social"
        value={razonSocial}
        onChange={(e)=>{setRazonSocial(e.target.value)}}
        ></input>


        <label>Documento</label>
        <input 
        type="text" 
        placeholder="Documento"
        value={documento}
        onChange={(e)=>{setDocumento(e.target.value)}}
        
        >

        </input>
          <label>Tipo de Cliente</label>
        <select
        value={tipoDeCliente}
        onChange={(e)=>{setTipoDeCliente(e.target.value)}}

        >
            <option value={'precioBase'}>Cliente Final</option>
            <option value={'precioCliente'}>Cliente</option>
            <option value={'precioCorralon'}>Corralón</option>
            <option value={'precioFerreteria'}>Ferretería</option>
        </select>

        <label>Condición frente al IVA</label>
        <select
        value={cfIva}
        onChange={(e)=>{setCfIva(e.target.value)}}

        >
           <option value={'responsable inscripto'} >Responsable Inscripto</option>
            <option value={'monotributista'}>Monotributista</option>
            <option value={'sujeto excento'}>Sujeto Excento</option>
            <option value={'consumidor final'}>Consumidor Final</option>
            <option value={'factura x'}>Factura X</option>
        </select>

        <label>Correo</label>
        <input 
        type="email" 
        placeholder="Correo"
        value={correo}
        onChange={(e)=>{setCorreo(e.target.value)}}
        ></input>

        <label>Telefono</label>
        <input 
        type="text" 
        placeholder="Teléfono"
        value={telefono}
        onChange={(e)=>{setTelefono(e.target.value)}}
        ></input>

        <label>Dirección</label>
        <input 
        type="text" 
        placeholder="Direccion"
        value={direccion}
        onChange={(e)=>{setDireccion(e.target.value)}}
        ></input>

        <label>Estado</label>
        <select
        value={estado}
        onChange={(e)=>{setEstado(e.target.value)}}

        >
            <option value={'activo'} >Activo</option>
            <option value={'inactivo'}>Inactivo</option>
        </select>
      

        <Button size={'smallBlock'} margin={'2em 0'}>Modificar</Button>
    </Column>


</Row>

</Form>
  </Modal>
  </>
     );
}
 
export default ModalEditar;
import { useState, useEffect } from "react";
import { db } from "../firebase/firebaseConfig";
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
} from "firebase/firestore";

const useObtenerProductos = () => {
  const [productos, setProductos] = useState([]);

  useEffect(() => {
    const consulta = query(
      collection(db, "productos"),
      where("estado", "==", "activo"),
      orderBy("nombre", "asc")
    );

    const unsub = onSnapshot(consulta, (snapshot) => {
      const dataSnapShot = snapshot.docs.map((productos) => {
        return [
          productos.id,
          productos.data().nombre,
          productos.data().cant,
          productos.data().cantMin,
          productos.data().cantXUnidad,
          productos.data().idOrigen,
          productos.data().estado,
          productos.data().presentacion,
          productos.data().codigo,
          productos.data().precioBase,
          productos.data().precioCliente,
          productos.data().precioCorralon,
          productos.data().precioFerreteria,
        ];
      });
      setProductos(dataSnapShot);
    });

    return unsub;
  }, []);
  return [productos];
};

export default useObtenerProductos;

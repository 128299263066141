import { db } from "./firebaseConfig";
import { collection, addDoc } from "firebase/firestore";
const crearCliente = ({
    nombre,
    correo,
    telefono,
    direccion,
    estado,
    documento,
    cfiva,
    razonSocial,
    tipoDeCliente
}) => {
 
        return addDoc(collection(db, "clientes"), {
            nombre:nombre,
            correo:correo,
            telefono:telefono,
            direccion:direccion,
            estado:estado,
            documento:documento,
            cfiva:cfiva,
            razonSocial:razonSocial,
            tipoDeCliente:tipoDeCliente
        });
        

}
 
export default crearCliente;
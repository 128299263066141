import System from '../../componentes/sistema';
import { DivPlayaStyles, CardPlaya, HeaderCardPlaya, BodyCardPlaya, DivItemPlaya } from './style'
import useObtenerOrdenes from "../../hooks/useObtenerOrdenes";
import ModalEditarOrden from "./modalEditarOrden";
import { useState } from 'react';
import { useEffect } from 'react';

const Playa = () => {
    const [viewModalEditar, setViewModalEditar] = useState(false);
    const [idEditar, SetidEditar] = useState('');
    const [queryByDay, setQueryByDay] = useState(true);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const { ordenes } = useObtenerOrdenes(startDate, endDate, queryByDay);
    const [pendiente, setPendiente] = useState([]);
    const [enProceso, setEnProceso] = useState([]);
    const [entregado, setEntregado] = useState([]);
    const modalEditar = (id) => {

        SetidEditar(id);
        setViewModalEditar(true);

    }


    useEffect(() => {
        if (ordenes && ordenes.length > 0) {
            const arrayPendientes = ordenes.filter((orden) => {
                return orden.estado === 'pendiente'
            })
            const arrayEnProceso = ordenes.filter((orden) => {
                return orden.estado === 'en proceso'
            })
            const arrayEntregados = ordenes.filter((orden) => {
                return orden.estado === 'entregado'
            })
            setPendiente(arrayPendientes)
            setEnProceso(arrayEnProceso)
            setEntregado(arrayEntregados)
        }
    }, [ordenes])
    return (
        <System>
            <ModalEditarOrden viewModal={viewModalEditar} setViewModal={setViewModalEditar} idEditar={idEditar}></ModalEditarOrden>

            <DivPlayaStyles>
                <CardPlaya>
                    <HeaderCardPlaya>Pendiente</HeaderCardPlaya>
                    <BodyCardPlaya>
                        {pendiente.length > 0 ?

                            pendiente.map((orden) => {

                                return (
                                    <DivItemPlaya key={orden.idOrden} onClick={() => { modalEditar(orden.idOrden) }}>{orden.cliente.nombre}</DivItemPlaya>

                                )

                            })

                            : <></>
                        }


                    </BodyCardPlaya>
                </CardPlaya>
                <CardPlaya>
                    <HeaderCardPlaya>En Proceso</HeaderCardPlaya>
                    <BodyCardPlaya>
                        {enProceso.length > 0 ?

                            enProceso.map((orden) => {

                                return (
                                    <DivItemPlaya key={orden.idOrden} onClick={() => { modalEditar(orden.idOrden) }}>{orden.cliente.nombre}</DivItemPlaya>

                                )

                            })

                            : <></>
                        }


                    </BodyCardPlaya>
                </CardPlaya>
                <CardPlaya>
                    <HeaderCardPlaya>Entregado</HeaderCardPlaya>
                    <BodyCardPlaya>
                        {entregado.length > 0 ?

                            entregado.map((orden) => {

                                return (
                                    <DivItemPlaya key={orden.idOrden} onClick={() => { modalEditar(orden.idOrden) }}>{orden.cliente.nombre}</DivItemPlaya>

                                )

                            })

                            : <></>
                        }


                    </BodyCardPlaya>
                </CardPlaya>


            </DivPlayaStyles>
        </System>

    );
}

export default Playa;
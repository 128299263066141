import styled from 'styled-components';

const OrdenesStyle = styled.div`
  width: 90%;
  margin: 1em auto;
  background: #fff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
`;
const ThumbOrdenes = styled.div`
  width: 95%;
  margin: 1em auto;
`;
const DateContainer = styled.div`
  width: 20%;
  margin-left: 1em;
  padding: 1em;
`;
const DivTotales = styled.div`
  width: 100%;
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  div {
    display: flex;
    flex-direction: column;
    h5 {
      margin: 0;
    }
  }
`;

export { OrdenesStyle, ThumbOrdenes, DateContainer, DivTotales };

import styled from "styled-components";
import { useState, useRef, useEffect } from "react";
import DataUpdate from "../../firebase/actualizarDato";
import Alert from "../alert";

const CellEdit = ({ value, idDoc, cellName, collection }) => {
    const [viewEdit, setViewEdit] = useState(false)
    const [valueInput, SetValueInput] = useState('')
    const [estadoAlert, setEstadoAlert] = useState(false);
    const [mensajeAlert, setMensajeAlert] = useState({});
    const ref = useRef(null)

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setViewEdit(false);
        }
    };

    const handleChange = () => {
        SetValueInput(value);
        setViewEdit(true);
    }

    const enviarDato = () => {
        try {

            DataUpdate({
                idDoc: idDoc,
                collection: collection,
                ObjValues: {
                    [cellName]: valueInput
                }

            });
            setEstadoAlert(true);
            setMensajeAlert({
                mensajeAlert: 'La Celda se actualizó correctamente',
                type: 'success'
            })

        } catch (err) {
            setEstadoAlert(true);

            let mensaje;
            switch (err.code) {
                default:
                    mensaje = "Ocurrió un error"
                    break;
            }
            setMensajeAlert({
                mensajeAlert: mensaje,
                type: 'error'
            })
        }

        setViewEdit(false)
    }


    return (
        <>
            <Alert
                mensajeAlert={mensajeAlert.mensajeAlert}
                type={mensajeAlert.type}
                estadoAlert={estadoAlert}
                setEstadoAlert={setEstadoAlert}
            ></Alert>

            <Container onClick={() => { handleChange() }} ref={ref}>
                {viewEdit ?
                    <Input
                        type="number"
                        value={valueInput}
                        onChange={(e) => { SetValueInput(e.target.value) }}
                        onKeyDown={(e) => { if (e.key === 'Enter') { enviarDato() } }}
                        min={0}
                    />
                    : <span>{value}</span>
                }
            </Container>



        </>
    );
}

export default CellEdit;



const Container = styled.div`
            width: 100%;
            `

const Input = styled.input`
            width:100%;
            height:38px;
            padding: .375rem .75rem;
            margin:5px 0px;
            box-sizing:border-box;
            border-radius: .25rem;
            border: 1px solid #ced4da;
            outline:none;

            :focus{
                border:2px solid var(--primary);
        
    }

            `

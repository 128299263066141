import FileSaver from "file-saver";
import XLSX from 'sheetjs-style'
import XlsxIcon from '../icons/xslx'
import styled from "styled-components";


const fileType='application/vnd.openxmlformats-officedocument.spreadheetml.sheet;charset=UTF-8';
const fileExtension='.xlsx'


const ExcelExport = ({excelData,fileName}) => {
    
    const exportToExcel=async()=>{
        const ws=XLSX.utils.json_to_sheet(excelData);
        const wb={Sheets:{'data':ws},SheetNames:['data']};
        const excelBuffer=XLSX.write(wb, {bookType:'xlsx', type:'array'})
        const data= new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data,fileName + fileExtension);
    }
    return ( 
        <Button
        onClick={()=>{exportToExcel(fileName)}}
        > <XlsxIcon width={'30px'}/> </Button>
     );
}
 
export default ExcelExport;

const Button=styled.button`
background: transparent;
border: none;
outline:none ;
cursor: pointer;
`
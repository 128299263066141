import { useCallback, useEffect, useState } from "react";
import Form from "../../../componentes/form";
import Row from "../../../componentes/Row";
import Column from "../../../componentes/column";
import Button from "../../../componentes/button";
import Modal from "../../../componentes/modal";
import Alert from "../../../componentes/alert";
import actualizarProducto from "../../../firebase/actualizarProducto";
import useObtenerStock from "../../../hooks/useObenterStock";
import { DivAvisoCantidadEnAlmacen } from "../style";

import useObtenerProductoPorId from "../../../hooks/useObtenerProductoPorId";

const ModalEditarProductos = ({ viewModal, setViewModal, idEditar }) => {
  const [product] = useObtenerProductoPorId(idEditar, viewModal);
  const [stock] = useObtenerStock();
  const [estadoAlert, setEstadoAlert] = useState(false);
  const [mensajeAlert, setMensajeAlert] = useState({});
  const [nombre, setNombre] = useState("");
  const [cant, setCant] = useState(1);
  const [addCant, setAddCant] = useState(0);
  const [cantMin, setCantMin] = useState(1);
  const [presentacion, setPresentacion] = useState("");
  const [cantPorUnidad, setCantPorUnidad] = useState(1);
  const [codigo, setCodigo] = useState("");
  const [OrigenStockList, setOrigenStockList] = useState([]);
  const [origenData, setOrigenData] = useState([]);
  const [almacenMax, setAlmacenMax] = useState(1);
  const [selectTipoOrigen, setSelectTipoOrigen] = useState("");
  const [selectOrigen, setSelectOrigen] = useState("");
  const [precioBase, setPrecioBase] = useState(0);
  const [precioCliente, setPrecioCliente] = useState(0);
  const [precioCorralon, setPrecioCorralon] = useState(0);
  const [precioFerreteria, setPrecioFerreteria] = useState(0);
  const [enableAddCAnt, setEnableAddCant] = useState(false);



  //Esta función obtiene la data del origen del stock seleccionado y calcula
  //el máximo producto posible en unidades (kg, L, ect)
  const getOrigenData = useCallback(
    (idOrigenSeleccionado) => {
      if (idOrigenSeleccionado !== "") {
        const [arrayResult] = stock.filter((result) => {
          return result[0] === idOrigenSeleccionado;
        });

        setOrigenData(arrayResult);
        setAlmacenMax(
          (parseFloat(arrayResult[5]) * parseFloat(arrayResult[9])).toFixed(2)
        );
      }
    },
    [stock]
  );

  //Esta Función Obtiene lista de Origenes según el tipo de origen seleccionado
  const getListaDeOrigenStock = useCallback(
    (nombreTipoOrigen) => {
      if (nombreTipoOrigen !== "") {
        const arrayResult = stock.filter((result) => {
          return result[3] === nombreTipoOrigen;
        });

        setOrigenStockList(arrayResult);
        if (arrayResult.length > 0) {
          getOrigenData(arrayResult[0][0]);
        } else if (arrayResult.length === 0) {
          setOrigenData([]);
        }
      }
    },
    [getOrigenData, stock]
  );
  //Esta Función Obtiene lista de Origenes según el tipo de origen inicial del producto
  const getListaDeOrigenStockInicio = useCallback(
    (nombreTipoOrigen, idSeleccionado) => {
      if (nombreTipoOrigen !== "") {
        const arrayResult = stock.filter((result) => {
          return result[3] === nombreTipoOrigen;
        });

        setOrigenStockList(arrayResult);
        if (arrayResult.length > 0) {
          getOrigenData(idSeleccionado);
        } else if (arrayResult.length === 0) {
          setOrigenData([]);
        }
      }
    },
    [getOrigenData, stock]
  );

  useEffect(() => {
    if (product && product.length !== 0) {
      const [stockOrigen] = stock.filter((stockItem) => {
        return stockItem[0] === product.data().idOrigen;
      });
      setSelectTipoOrigen(stockOrigen[3]);
      getListaDeOrigenStockInicio(stockOrigen[3], stockOrigen[0]);
      setSelectOrigen(stockOrigen[0]);
      setNombre(product.data().nombre);
      setCant(product.data().cant);
      setAddCant(0);
      setCantMin(product.data().cantMin);
      setPresentacion(product.data().presentacion);
      setCantPorUnidad(product.data().cantXUnidad);
      setCodigo(product.data().codigo);
      setPrecioBase(product.data().precioBase);
      setPrecioCliente(product.data().precioCliente);
      setPrecioCorralon(product.data().precioCorralon);
      setPrecioFerreteria(product.data().precioFerreteria);
      setEnableAddCant(false)
    }
  }, [product, stock, getListaDeOrigenStockInicio]);

  const ProductoMax = Math.floor(almacenMax / cantPorUnidad) - addCant;

  const registrarProducto = async (e) => {
    e.preventDefault();

    if (origenData.length === 0) {
      setEstadoAlert(true);
      setMensajeAlert({
        mensajeAlert: "Elige un Origen Stock",
        type: "error",
      });

      return;
    }
    if (
      nombre === "" ||
      cant === "" ||
      cantMin === "" ||
      codigo === "" ||
      addCant === ""
    ) {
      setEstadoAlert(true);
      setMensajeAlert({
        mensajeAlert: "Por favor rellena todos los campos",
        type: "error",
      });

      return;
    }
    const expresionRegular = /^[0-9]+$/;
    if (!expresionRegular.test(cant)) {
      setEstadoAlert(true);
      setMensajeAlert({
        mensajeAlert: "Stock debe ser un numero entero positivo",
        type: "error",
      });
      return;
    }
    if (!enableAddCAnt && !expresionRegular.test(addCant)) {
      setEstadoAlert(true);
      setMensajeAlert({
        mensajeAlert: "Agregar Stock debe ser un numero entero positivo",
        type: "error",
      });
      return;
    }
    if (!expresionRegular.test(cantMin)) {
      setEstadoAlert(true);
      setMensajeAlert({
        mensajeAlert: "Stock Min debe ser un numero entero positivo",
        type: "error",
      });
      return;
    }

    if (ProductoMax < 0) {
      setEstadoAlert(true);
      setMensajeAlert({
        mensajeAlert: "Te excediste en el máximo permitido de almacén",
        type: "error",
      });
      return;
    }
    let stockRestanteTotal;
    let stockRestanteUnidades;
    let newCant;
    if (enableAddCAnt) {
      stockRestanteTotal = almacenMax;//no afectar al almacen
      stockRestanteUnidades = Number(
        (stockRestanteTotal / parseFloat(origenData[9])).toFixed(2)
      );
      newCant = 0;

    } else {
      stockRestanteTotal = parseFloat(
        almacenMax - cantPorUnidad * addCant
      ).toFixed(2); //eje:800kg - 20kg * 10unidades
      stockRestanteUnidades = Number(
        (stockRestanteTotal / parseFloat(origenData[9])).toFixed(2)
      );
      newCant = parseInt(cant, 10) + parseInt(addCant, 10);
    }

    try {
      actualizarProducto({
        idDoc: idEditar,
        idOrigen: origenData[0],
        nombre: nombre,
        cant: newCant,
        cantMin: parseInt(cantMin, 10),
        codigo: codigo,
        precioBase: parseFloat(precioBase),
        precioCliente: parseFloat(precioCliente),
        precioCorralon: parseFloat(precioCorralon),
        precioFerreteria: parseFloat(precioFerreteria),
        stockRestanteTotal: stockRestanteTotal,
        stockRestanteUnidades: stockRestanteUnidades,
      });
      setEstadoAlert(true);
      setMensajeAlert({
        mensajeAlert: "El Producto se actualizó correctamente",
        type: "success",
      });
      setViewModal(false);

    } catch (err) {
      setEstadoAlert(true);

      let mensaje;
      switch (err.code) {
        default:
          mensaje = "Ocurrió un error";
          break;
      }
      setMensajeAlert({
        mensajeAlert: mensaje,
        type: "error",
      });
    }
  };
  return (
    <>
      <Alert
        mensajeAlert={mensajeAlert.mensajeAlert}
        type={mensajeAlert.type}
        estadoAlert={estadoAlert}
        setEstadoAlert={setEstadoAlert}
      ></Alert>

      <Modal
        titulo={"Editar Producto"}
        estadoModal={viewModal}
        cambiarEstado={setViewModal}
        tamano={40}
      >
        <Form onSubmit={registrarProducto}>
          <Row>
            <Column w={90}>
              <h5>Origen del Producto</h5>
              <label>Tipo de Origen</label>
              <select
                value={selectTipoOrigen}
                onChange={(e) => {
                  getListaDeOrigenStock(e.target.value);
                  setSelectTipoOrigen(e.target.value);
                }}
                disabled
              >
                <option value={"aridos"}>Áridos</option>
                <option value={"hierros"}>Hierros</option>
                <option value={"cales"}>Cales</option>
                <option value={"ladrillos"}>Ladrillos</option>
                <option value={"ceramicas"}>Cerámicas</option>
                <option value={"porcelanato"}>Porcelanato</option>
                <option value={"cementos"}>Cementos</option>
                <option value={"aditivos"}>Aditivos</option>
                <option value={"revestimientos"}>Revestimientos</option>
                <option value={"pegamentos"}>Pegamentos</option>
                <option value={"Baño"}>Baño</option>
                <option value={"yesos"}>Yesos</option>
                <option value={"Herramientas"}>Herramientas</option>
                <option value={"Accesorios"}>Accesorios</option>
              </select>
              <label>Origen Stock</label>
              <select
                disabled
                value={selectOrigen}
                onChange={(e) => {
                  getOrigenData(e.target.value);
                  setSelectOrigen(e.target.value);
                }}
              >
                {OrigenStockList.map((dataOrigen) => {
                  return (
                    <option key={dataOrigen[0]} value={dataOrigen[0]}>
                      {dataOrigen[1]}
                    </option>
                  );
                })}
              </select>
            </Column>
          </Row>
          <hr></hr>
          <Row>
            <Column w={90}>
              <h5>Datos del Producto</h5>

              <label>Código</label>
              <input
                type="text"
                placeholder="Nombre"
                value={codigo}
                onChange={(e) => {
                  setCodigo(e.target.value);
                }}
              ></input>
              <label>Nombre del Producto</label>
              <input
                type="text"
                placeholder="Nombre"
                value={nombre}
                onChange={(e) => {
                  setNombre(e.target.value);
                }}
              ></input>
              <label>Precio Cliente Final</label>
              <input
                type="number"
                placeholder="Precio"
                value={precioBase}
                onChange={(e) => {
                  setPrecioBase(e.target.value ? e.target.value : 0);
                }}
              ></input>

              <label>Precio Cliente </label>
              <input
                type="number"
                placeholder="Precio Cliente"
                value={precioCliente}
                onChange={(e) => {
                  setPrecioCliente(e.target.value ? e.target.value : 0);
                }}
              ></input>
              <label>Precio Corralón</label>
              <input
                type="number"
                placeholder="Precio Corralón"
                value={precioCorralon}
                onChange={(e) => {
                  setPrecioCorralon(e.target.value ? e.target.value : 0);
                }}
              ></input>
              <label>Precio Ferretería </label>
              <input
                type="number"
                placeholder="Precio Ferretería"
                value={precioFerreteria}
                onChange={(e) => {
                  setPrecioFerreteria(e.target.value ? e.target.value : 0);
                }}
              ></input>
              <label>Presentación</label>
              <select
                disabled
                value={presentacion}
                onChange={(e) => {
                  setPresentacion(e.target.value);
                }}
              >
                <option value={"bolsas"}>Bolsas</option>
                <option value={"cajas"}>Cajas</option>
                <option value={"paquetes"}>Paquetes</option>
                <option value={"Tachos"}>Tachos</option>
                <option value={"unidades"}>Unidades</option>
              </select>
            </Column>
          </Row>
          <hr></hr>
          <Row>
            <Column w={90}>
              <h5>Proporcion de Producto</h5>
              {origenData.length > 0 ? (
                <DivAvisoCantidadEnAlmacen>
                  <p>Cantidad disponible en Almacén:</p>
                  <p>
                    {origenData[5]} {origenData[4]}(s) de {origenData[9]}{" "}
                    {origenData[8]} ({almacenMax} {origenData[8]} )
                  </p>
                </DivAvisoCantidadEnAlmacen>
              ) : (
                <></>
              )}
              <label>
                ¿Cuánt@s {origenData[8]} va a tener cada{" "}
                <strong>{nombre}</strong>?{" "}
              </label>
              <input
                disabled
                type="number"
                placeholder="Cantidad"
                value={cantPorUnidad}
                onChange={(e) => {
                  setCantPorUnidad(e.target.value);
                }}
                min={1}
              ></input>

              <label>Stock</label>
              <input
                disabled
                type="number"
                placeholder="Stock"
                value={cant}
                onChange={(e) => {
                  setCant(e.target.value);
                }}
                min={1}
              ></input>

              <label>
                Agregar Stock
                {ProductoMax >= 0 ? (
                  <small>
                    (te quedan {Math.round(ProductoMax)} {presentacion})
                  </small>
                ) : (
                  <small style={{ marginLeft: "0.5em", color: "red" }}> (ya no quedan {presentacion}(s))</small>
                )}
              </label>
              <input
                disabled={enableAddCAnt}
                type="number"
                placeholder="Agregar Stock"
                value={addCant}
                onChange={(e) => {
                  setAddCant(e.target.value);
                }}

              ></input>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'start', marginBottom: '1em' }}>
                <input type="checkbox" value="Paneer" onClick={() => { setEnableAddCant(!enableAddCAnt) }} />Reiniciar Stock (a cero)

              </div>
              <label>Stock Mínimo</label>
              <input
                type="number"
                placeholder="Stock Mínimo"
                value={cantMin}
                onChange={(e) => {
                  setCantMin(e.target.value);
                }}
                min={0}
              ></input>

              <Button size={"smallBlock"} margin={"2em 0"}>
                Actualizar
              </Button>
            </Column>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ModalEditarProductos;


import Row from "../../../componentes/Row";
import Column from "../../../componentes/column";
import Modal from "../../../componentes/modal";
import { DivTableClientes } from "../../clientes/style";
import Tabla from "../../../componentes/tabla";
import useObtenerClientes from "../../../hooks/useObtenerClientes";
import { contextCaja } from "../../../context/contextCaja";
import { useContext, useEffect, useState } from "react";
import Buscador from "../../../componentes/buscador";

const ModalClientesCaja = ({ viewModal, setViewModal }) => {
    const [clientes] = useObtenerClientes();
   
    const [dataTabla, setDataTabla] = useState([]);
    const [dataTablaStatic, setDataTablaStactic] = useState([]);
    const { agregarCliente } = useContext(contextCaja);

    useEffect(() => {
        setDataTabla(clientes)
        setDataTablaStactic(clientes)
    }, [viewModal, clientes])

    const seleccionarCliente = (idCliente) => {

        const [clienteSeleccionado] = clientes.filter((cliente) => {
            return cliente[0] === idCliente;
        })
        const buscarTipo=(tipoDePrecio)=>{
            let tipoDeCliente=null;
            switch (tipoDePrecio) {
                case "precioBase":
                    tipoDeCliente="Cliente Final"
                    break;
                case "precioCliente":
                    tipoDeCliente="Cliente";
                    break;
                case "precioCorralon":
                    tipoDeCliente="Corralón";
                    break;
                case "precioFerreteria":
                    tipoDeCliente="Ferretería";
                    break;
            
                default:
                    tipoDeCliente="Cliente Final"
                    break;
            
            }
            return tipoDeCliente;
        }
       

        agregarCliente({
            id: clienteSeleccionado[0],
            nombre: clienteSeleccionado[1],
            correo: clienteSeleccionado[5],
            telefono: clienteSeleccionado[6],
            direccion: clienteSeleccionado[7],
            tipoDePrecio: clienteSeleccionado[9],
            tipo: buscarTipo(clienteSeleccionado[9]),
            cfiva: clienteSeleccionado[4]
        }

        );
        setViewModal(false)

    }


    return (
        <>


            <Modal titulo={"Agregar Cliente"} estadoModal={viewModal} cambiarEstado={setViewModal} tamano={80}>
                <Row>
                    <Column w={50}>
                        <Buscador
                            setDataTabla={setDataTabla}
                            dataTablaStatic={dataTablaStatic}
                        ></Buscador>
                    </Column>
                </Row>
                    <Row>

                        <DivTableClientes>
                            {dataTabla.length > 0 ?

                                <Tabla>
                                    <>
                                        <thead>
                                            <tr>
                                                <th>COD </th>
                                                <th>Nombre </th>
                                                <th>Dirección </th>
                                                <th>CFIVA </th>
                                                <th>Acciones </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataTabla.map((cliente) => {
                                               
                                                return (
                                                    <tr key={cliente[0]}>
                                                        <td>{cliente[3]} </td> 
                                                        <td>{cliente[1]} </td>
                                                        <td>{cliente[7]} </td>
                                                        <td>{cliente[4]} </td>
                                                        <td>

                                                            <div >
                                                                <button onClick={() => { seleccionarCliente(cliente[0]) }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg>
                                                                </button>

                                                            </div>

                                                        </td>

                                                    </tr>
                                                )
                                            })}

                                        </tbody>
                                    </>
                                </Tabla>
                                : <></>}
                        </DivTableClientes>



                    </Row>


            </Modal>
        </>
    );
}

export default ModalClientesCaja;
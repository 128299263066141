import { useState, useEffect } from "react";
import { db } from "../firebase/firebaseConfig";
import { collection, onSnapshot, query  } from "firebase/firestore";




const useGETFormaDePago = () => {

    const [ formaDePago, setFormaDePago]=useState([]);

    useEffect(()=>{
        const consulta=query(
            collection(db, 'formaDePago')
        )
       
            const unsub = onSnapshot(consulta, (snapshot) => {
                    const dataSnapShot=snapshot.docs.map((formaDePago)=>{
                       
                        return {
                        
                                id:formaDePago.id,
                                nombre:formaDePago.data().nombre,
                            }
                            
                             
                        
                    });
                    setFormaDePago(dataSnapShot) ;
            });
          
            
            return unsub;
           
    },[])
    return [formaDePago];
}
 
export default useGETFormaDePago;
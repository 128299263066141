import { ItemProducto } from "./style";
import { useContext, useState } from "react";
import { contextCaja } from "../../../context/contextCaja";
import { useEffect } from "react";

const ItemProductoCaja = ({ productoItem }) => {
  const [cant, setCant] = useState(1);
  const [stockTemp, setStockTemp] = useState(1);

  const { eliminarProductoCaja, setCantidadProducto } = useContext(contextCaja);

  useEffect(() => {
    setCantidadProducto(productoItem.idProducto, cant);
    setStockTemp(productoItem.stock);
  }, [cant, productoItem.idProducto, productoItem.stock]);

  const sumarCant = () => {
    setCant(cant + 1);
  };
  const restarCant = () => {
    if (cant > 1) {
      setCant(cant - 1);
    } else {
      setCant(1);
    }
  };
  const borrarItemProducto = () => {
    eliminarProductoCaja(productoItem.idProducto);
  };

  const setCantidad = (val) => {
    const num = Number(val);

    if (Math.sign(num) === 1 && Number.isInteger(num)) {
      if (stockTemp > num) {
        setCant(num);
      } else {
        setCant(stockTemp);
      }
    } else {
      setCant(1);
    }
  };

  return (
    <>
      <ItemProducto>
        <td>{productoItem.cod} </td>
        <td>{productoItem.nombre} </td>
        <td>${productoItem.precio} </td>
        <td>${Number(productoItem.precio) * Number(productoItem.cant)} </td>
        <td>
          <div>

            <button
              disabled={stockTemp > cant ? false : true}
              onClick={() => {
                sumarCant();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                />
              </svg>
            </button>

            <input
              value={cant}
              onChange={(e) => {
                setCantidad(e.target.value);
              }}
            ></input>
            <button
              onClick={() => {
                restarCant();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"
                />
              </svg>
            </button>
          </div>
        </td>
        <td>
          <div>
            <button
              onClick={() => {
                borrarItemProducto();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
              </svg>
            </button>
          </div>
        </td>
      </ItemProducto>
    </>
  );
};

export default ItemProductoCaja;

import { useState } from "react";
import Form from "../../../../componentes/form";
import Row from "../../../../componentes/Row";
import Column from "../../../../componentes/column";
import Button from "../../../../componentes/button";
import Modal from "../../../../componentes/modal";
import Alert from "../../../../componentes/alert";
import crearTipoDeCliente from "../../../../firebase/crearTipoDeCliente";



const ModalAgregarTipoDeCliente = ({viewModal, setViewModal}) => {
    
    const [estadoAlert, setEstadoAlert] = useState(false);
    const [mensajeAlert, setMensajeAlert] = useState({});
    const [tipo, setTipo] = useState('');
    const [descuento, setDescuento] = useState('');
  
    
    



    const registrarTipoDeCliente=async(e)=>{
        e.preventDefault();
       
      
       
        if (tipo==='') {
            setEstadoAlert(true);
            setMensajeAlert({
                mensajeAlert:'Tipo no puede quedar vacío',
                type:'error'
            })
           
            return;
        }
       
       
        try {
            
            
            crearTipoDeCliente({
                tipo:tipo,
                descuento:descuento,

            });
            setEstadoAlert(true);
            setMensajeAlert({
                mensajeAlert:'El Tipo  se agregó correctamente',
                type:'success'
            })
            setViewModal(false);
            
        } catch (err) {
            setEstadoAlert(true);

           let mensaje;
            switch (err.code) {
                
                default:
                    mensaje="Ocurrió un error"
                    
                    break;
            }
            setMensajeAlert({
                mensajeAlert:mensaje,
                type:'error'
            })
        }
    }
    return ( 
        <>
        
        <Alert
            mensajeAlert={mensajeAlert.mensajeAlert}
            type={mensajeAlert.type}
            estadoAlert={estadoAlert}
            setEstadoAlert={setEstadoAlert}
            
            ></Alert>
        
        <Modal titulo={"Agregar nuevo Tipo de Cliente"} estadoModal={viewModal} cambiarEstado={setViewModal} tamano={40}>
        <Form onSubmit={registrarTipoDeCliente}>

            <Row>

                <Column w={90} margin={'auto'}>

                    <label>Tipo</label>
                    <input 
                    type="text" 
                    placeholder="Tipo"
                    value={tipo}
                    onChange={(e)=>{setTipo(e.target.value)}}
                    ></input>
                     <label>Descuento</label>
                    <input 
                    type="number" 
                    placeholder="Descuento (%)"
                    value={descuento}
                    onChange={(e)=>{setDescuento(e.target.value)}}
                    ></input>

                    <Button size={'smallBlock'} margin={'2em 0'}>Registrar</Button>
                </Column>


            </Row>

        </Form>
    </Modal>
    </>
     );
}
 
export default ModalAgregarTipoDeCliente;
import Form from "../../../componentes/form";
import Row from "../../../componentes/Row";
import Column from "../../../componentes/column";
import Button from "../../../componentes/button";
import Modal from "../../../componentes/modal";
import Alert from "../../../componentes/alert";
import { useEffect, useState } from "react";
import useObtenerStockPorId from "../../../hooks/useObtenerStockporId";
import actualizarStock from "../../../firebase/actualizarStock";
import useObtenerProveedores from "../../../hooks/useObtenerProveedores";

const ModalEditarStock = ({
  viewModalEditar,
  setViewModalEditar,
  idEditar,
}) => {
  const [proveedores] = useObtenerProveedores();
  const [stockById] = useObtenerStockPorId(idEditar, viewModalEditar);
  const [estadoAlert, setEstadoAlert] = useState(false);
  const [mensajeAlert, setMensajeAlert] = useState({});
  const [nombre, setNombre] = useState("");
  const [marca, setMarca] = useState("");
  const [tipo, setTipo] = useState("");
  const [unidad, setUnidad] = useState("");
  const [cant, setCant] = useState(0);

  const [cantMin, setCantMin] = useState("");
  const [selectProveedor, setSelectProveedor] = useState("");
  const [presentacion, setPresentacion] = useState("");
  const [cantTotalPorUnidad, setCantTotalPorUnidad] = useState(0);
  const [cantPorUnidad, setCantPorUnidad] = useState(0);

  useEffect(() => {
    if (stockById && stockById.length !== 0) {
      setNombre(stockById.data().nombre);
      setMarca(stockById.data().marca);
      setTipo(stockById.data().tipo);
      setUnidad(stockById.data().unidad);
      setCant(stockById.data().cant);

      setCantMin(stockById.data().cantMin);
      setPresentacion(stockById.data().presentacion);

      setCantPorUnidad(stockById.data().cantXUnidad);

      for (let i = 0; i < proveedores.length; i++) {
        if (proveedores[i].includes(stockById.data().idProveedor)) {
          setSelectProveedor(proveedores[i][1]);
          return;
        }
      }
    }
  }, [stockById, proveedores]);

  useEffect(() => {
    setCantTotalPorUnidad((Number(cant) * Number(cantPorUnidad)).toFixed(2));
  }, [cant, cantPorUnidad]);

  const editar = async (e) => {
    e.preventDefault();

    const expresionRegular = /^[0-9]+$/;

    if (!expresionRegular.test(cantMin)) {
      setEstadoAlert(true);
      setMensajeAlert({
        mensajeAlert: "Cantidad Min debe ser un numero entero",
        type: "error",
      });
      return;
    }

    if (
      nombre === "" ||
      marca === "" ||
      cant === "" ||
      cantMin === ""

    ) {
      setEstadoAlert(true);
      setMensajeAlert({
        mensajeAlert: "Por favor rellena todos los campos",
        type: "error",
      });

      return;
    }
    let idProveedorNew;
    for (let i = 0; i < proveedores.length; i++) {
      if (proveedores[i].includes(selectProveedor)) {
        idProveedorNew = proveedores[i][0];
      }
    }

    try {
      actualizarStock({
        idDoc: idEditar,
        nombre: nombre,
        marca: marca,
        tipo: tipo,
        unidad: unidad,
        cant: parseInt(cant),
        cantMin: parseInt(cantMin, 10),
        idProveedor: idProveedorNew,
        presentacion: presentacion,
        cantXUnidad: cantPorUnidad,
        cantTotalxUnidad: cantTotalPorUnidad,
      });
      setEstadoAlert(true);
      setMensajeAlert({
        mensajeAlert: "El Stock se actualizó correctamente",
        type: "success",
      });

      setViewModalEditar(false);
    } catch (err) {
      setEstadoAlert(true);

      let mensaje;
      switch (err.code) {
        default:
          mensaje = "Ocurrió un error";
          break;
      }
      setMensajeAlert({
        mensajeAlert: mensaje,
        type: "error",
      });
    }
  };

  return (
    <>
      <Alert
        mensajeAlert={mensajeAlert.mensajeAlert}
        type={mensajeAlert.type}
        estadoAlert={estadoAlert}
        setEstadoAlert={setEstadoAlert}
      ></Alert>

      <Modal
        titulo={"Editar Stock"}
        estadoModal={viewModalEditar}
        cambiarEstado={setViewModalEditar}
        tamano={40}
      >
        <Form onSubmit={editar}>
          <Row>
            <Column w={90} margin={"auto"}>
              <label>Nombre del Stock</label>
              <input
                type="text"
                placeholder="Nombre"
                value={nombre}
                onChange={(e) => {
                  setNombre(e.target.value);
                }}
              ></input>

              <label>Marca</label>
              <input
                type="text"
                placeholder="marca"
                value={marca}
                onChange={(e) => {
                  setMarca(e.target.value);
                }}
              ></input>

              <label>Proveedor</label>
              <select
                value={selectProveedor}
                onChange={(e) => {
                  setSelectProveedor(e.target.value);
                }}
              >
                {proveedores.length > 0 ? (
                  proveedores.map((proveedor) => {
                    return (
                      <option value={proveedor[1]} key={proveedor[0]}>
                        {proveedor[1]}
                      </option>
                    );
                  })
                ) : (
                  <></>
                )}
              </select>

              <label>Tipo de Stock</label>
              <select
                value={tipo}
                onChange={(e) => {
                  setTipo(e.target.value);
                }}
              >
                <option value={"aridos"}>Áridos</option>
                <option value={"hierros"}>Hierros</option>
                <option value={"cales"}>Cales</option>
                <option value={"ladrillos"}>Ladrillos</option>
                <option value={"ceramicas"}>Cerámicas</option>
                <option value={"porcelanato"}>Porcelanato</option>
                <option value={"cementos"}>Cementos</option>
                <option value={"aditivos"}>Aditivos</option>
                <option value={"revestimientos"}>Revestimientos</option>
                <option value={"pegamentos"}>Pegamentos</option>
                <option value={"Baño"}>Baño</option>
                <option value={"yesos"}>Yesos</option>
                <option value={"Herramientas"}>Herramientas</option>
                <option value={"Accesorios"}>Accesorios</option>
                <option value={"M"}>Metros</option>
              </select>
              <label>Presentación</label>
              <select
                value={presentacion}
                onChange={(e) => {
                  setPresentacion(e.target.value);
                }}
              >
                <option value={"bolsas"}>Bolsas</option>
                <option value={"cajas"}>Cajas</option>
                <option value={"paquetes"}>Paquetes</option>
                <option value={"Tachos"}>Tachos</option>
                <option value={"unidades"}>Unidades</option>
              </select>
              <label>Unidad de medida</label>
              <select
                value={unidad}
                onChange={(e) => {
                  setUnidad(e.target.value);
                }}
              >
                <option value={"Kg"}>Kilogramos</option>
                <option value={"L"}>Litros</option>
                <option value={"U"}>Unidad</option>
              </select>
              <label>
                ¿Cuant@s {unidad} trae cada {presentacion}?{" "}
              </label>
              <input
                disabled
                pattern="^[0-9.]*$"
                type="text"
                placeholder="Cant"
                value={cantPorUnidad}
                onChange={(e) => {
                  setCantPorUnidad(e.target.value);
                }}
              ></input>
              <label>Cantidad de {presentacion}</label>

              <input
                pattern="^[0-9.]*$"
                type="text"
                placeholder="Cant Stock"
                value={cant}
                onChange={(e) => {
                  setCant(e.target.value);
                }}
              ></input>


              <label>Cantidad Min de {presentacion}</label>
              <input
                pattern="^[0-9.]*$"
                type="text"
                placeholder="Cant Stock Min"
                value={cantMin}
                onChange={(e) => {
                  setCantMin(e.target.value);
                }}
              ></input>
              <label>Cantidad total en {unidad} </label>
              <input
                pattern="^[0-9]*$"
                disabled
                type="text"
                placeholder="Cant"
                value={cantTotalPorUnidad}
              ></input>

              <Button size={"smallBlock"} margin={"2em 0"}>
                Registrar
              </Button>
            </Column>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ModalEditarStock;

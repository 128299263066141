import { useEffect, useState } from "react";
import Form from "../../../componentes/form";
import Row from "../../../componentes/Row";
import Column from "../../../componentes/column";
import Button from "../../../componentes/button";
import Modal from "../../../componentes/modal";
import Alert from "../../../componentes/alert";
import crearStock from "../../../firebase/crearStock";
import useObtenerProveedores from "../../../hooks/useObtenerProveedores";

const ModalAgregarStock = ({ viewModal, setViewModal }) => {
  const [proveedores] = useObtenerProveedores();
  const [estadoAlert, setEstadoAlert] = useState(false);
  const [mensajeAlert, setMensajeAlert] = useState({});
  const [nombre, setNombre] = useState("");
  const [marca, setMarca] = useState("");
  const [tipo, setTipo] = useState("aridos");
  const [unidad, setUnidad] = useState("Kg");
  const [cant, setCant] = useState("");
  const [cantMin, setCantMin] = useState("");
  const [selectProveedor, setSelectProveedor] = useState("");
  const [presentacion, setPresentacion] = useState("bolsas");
  const [cantPorUnidad, setCantPorUnidad] = useState("");

  useEffect(() => {
    if (proveedores.length > 0) {
      setSelectProveedor(proveedores[0][0]);
    }
  }, [proveedores]);

  const registrarStock = async (e) => {
    e.preventDefault();

    const expresionRegular = /^[0-9]+$/;

    if (!expresionRegular.test(cantMin)) {
      setEstadoAlert(true);
      setMensajeAlert({
        mensajeAlert: "Cantidad Min debe ser un numero entero",
        type: "error",
      });
      return;
    }

    if (nombre === "" || marca === "" || cant === "" || cantMin === "") {
      setEstadoAlert(true);
      setMensajeAlert({
        mensajeAlert: "Por favor rellena todos los campos",
        type: "error",
      });

      return;
    }

    try {
      crearStock({
        nombre: nombre, //nombre stock
        marca: marca, //marca
        tipo: tipo, //aridos, hierros, cales, cementos,...
        unidad: unidad, //unidad de medida: ml, kg, unidad
        cant: parseFloat(cant), // cantidad stock ej: 10 bolsones  entraron en almacén
        cantMin: parseInt(cantMin, 10), //cantidad minima stock
        idProveedor: selectProveedor, //id del proveedor
        presentacion: presentacion, //packing: bolsas, cajas, paquetes,....
        cantXUnidad: cantPorUnidad, //cuanta unidades trae cada cant ej:cada bolson trae 100Kg
        cantTotalxUnidad: (
          parseFloat(cant) * parseFloat(cantPorUnidad)
        ).toFixed(2), //cuantas unidades(ml, kg, etc) salen de todo lo que entró en stock. Ej: con los 10 bolsones hacen 1000 Kgs.
      });
      setEstadoAlert(true);
      setMensajeAlert({
        mensajeAlert: "El Stock se agregó correctamente",
        type: "success",
      });
      setViewModal(false);
    } catch (err) {
      setEstadoAlert(true);

      let mensaje;
      switch (err.code) {
        default:
          mensaje = "Ocurrió un error";

          break;
      }
      setMensajeAlert({
        mensajeAlert: mensaje,
        type: "error",
      });
    }
  };
  return (
    <>
      <Alert
        mensajeAlert={mensajeAlert.mensajeAlert}
        type={mensajeAlert.type}
        estadoAlert={estadoAlert}
        setEstadoAlert={setEstadoAlert}
      ></Alert>

      <Modal
        titulo={"Agregar nuevo Stock de Almacén"}
        estadoModal={viewModal}
        cambiarEstado={setViewModal}
        tamano={40}
      >
        <Form onSubmit={registrarStock}>
          <Row>
            <Column w={90} margin={"auto"}>
              <label>Nombre del Stock</label>
              <input
                type="text"
                placeholder="Nombre"
                value={nombre}
                onChange={(e) => {
                  setNombre(e.target.value);
                }}
              ></input>

              <label>Marca</label>
              <input
                type="text"
                placeholder="marca"
                value={marca}
                onChange={(e) => {
                  setMarca(e.target.value);
                }}
              ></input>

              <label>Proveedor</label>
              <select
                value={selectProveedor}
                onChange={(e) => {
                  setSelectProveedor(e.target.value);
                }}
              >
                {proveedores.length > 0 ? (
                  proveedores.map((proveedor) => {
                    return (
                      <option value={proveedor[0]} key={proveedor[0]}>
                        {proveedor[1]}
                      </option>
                    );
                  })
                ) : (
                  <></>
                )}
              </select>
              <label>Tipo de Stock</label>
              <select
                value={tipo}
                onChange={(e) => {
                  setTipo(e.target.value);
                }}
              >
                <option value={"aridos"}>Áridos</option>
                <option value={"hierros"}>Hierros</option>
                <option value={"cales"}>Cales</option>
                <option value={"ladrillos"}>Ladrillos</option>
                <option value={"ceramicas"}>Cerámicas</option>
                <option value={"porcelanato"}>Porcelanato</option>
                <option value={"cementos"}>Cementos</option>
                <option value={"aditivos"}>Aditivos</option>
                <option value={"revestimientos"}>Revestimientos</option>
                <option value={"pegamentos"}>Pegamentos</option>
                <option value={"Baño"}>Baño</option>
                <option value={"yesos"}>Yesos</option>
                <option value={"Herramientas"}>Herramientas</option>
                <option value={"Accesorios"}>Accesorios</option>
              </select>
              <label>Presentación</label>
              <select
                value={presentacion}
                onChange={(e) => {
                  setPresentacion(e.target.value);
                }}
              >
                <option value={"bolsas"}>Bolsas</option>
                <option value={"cajas"}>Cajas</option>
                <option value={"paquetes"}>Paquetes</option>
                <option value={"Tachos"}>Tachos</option>
                <option value={"unidades"}>Unidades</option>
              </select>
              <label>Unidad de medida</label>
              <select
                value={unidad}
                onChange={(e) => {
                  setUnidad(e.target.value);
                }}
              >
                <option value={"Kg"}>Kilogramos</option>
                <option value={"L"}>Litros</option>
                <option value={"U"}>Unidad</option>
                <option value={"M"}>Metros</option>
              </select>
              <label>
                ¿Cuant@s {unidad} trae cada {presentacion}?{" "}
              </label>
              <input
                pattern="^[0-9.]*$"
                type="text"
                placeholder="Cantidad"
                value={cantPorUnidad}
                onChange={(e) => {
                  setCantPorUnidad(e.target.value);
                }}
              ></input>
              <label>{`Cantidad de ${presentacion}`}</label>
              <input
                pattern="^[0-9.]*$"
                type="text"
                placeholder="Cantidad Almacén"
                value={cant}
                onChange={(e) => {
                  setCant(e.target.value);
                }}
              ></input>
              <label>{`Cantidad Min de ${presentacion}`}</label>
              <input
                pattern="^[0-9]*$"
                type="text"
                placeholder="Cantidad Mínima"
                value={cantMin}
                onChange={(e) => {
                  setCantMin(e.target.value);
                }}
              ></input>

              <Button size={"smallBlock"} margin={"2em 0"}>
                Registrar
              </Button>
            </Column>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ModalAgregarStock;

import { db } from "./firebaseConfig";
import { doc, updateDoc } from "firebase/firestore";

const actualizarStock = async ({
  idDoc,
  nombre,
  marca,
  tipo,
  unidad,
  cant,
  cantMin,
  idProveedor,
  presentacion,
  cantXUnidad,
  cantTotalxUnidad,
}) => {
  const documento = doc(db, "stock", idDoc);

  return await updateDoc(documento, {
    nombre: nombre,
    marca: marca,
    tipo: tipo,
    unidad: unidad,
    cant: cant,
    cantMin: parseInt(cantMin, 10),
    idProveedor: idProveedor,
    presentacion: presentacion,
    cantXUnidad: cantXUnidad,
    cantTotalxUnidad: cantTotalxUnidad,
  });
};

export default actualizarStock;

import { db } from "./firebaseConfig";
//import { collection, addDoc } from "firebase/firestore";

import { writeBatch, doc } from "firebase/firestore";
// Get a new write batch

const actualizarProducto = async ({
  idDoc,
  idOrigen,
  nombre,
  cant,
  cantMin,
  codigo,
  stockRestanteUnidades,
  stockRestanteTotal,
  precioBase,
  precioCliente,
  precioCorralon,
  precioFerreteria,
}) => {
  const batch = writeBatch(db);
  // editar producto
  const nycRef = doc(db, "productos", idDoc);
  batch.update(nycRef, {
    nombre: nombre,
    cant: cant,
    cantMin: cantMin,
    codigo: codigo,
    idOrigen: idOrigen,
    precioBase: precioBase,
    precioCliente: precioCliente,
    precioCorralon: precioCorralon,
    precioFerreteria: precioFerreteria,
  });

  //Actualizar stock Almacen
  const sfRef = doc(db, "stock", idOrigen);
  batch.update(sfRef, {
    cant: stockRestanteUnidades,
    cantTotalxUnidad: stockRestanteTotal,
  });

  return await batch.commit();
};

export default actualizarProducto;

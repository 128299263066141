import { db } from "./firebaseConfig";
import { collection, addDoc } from "firebase/firestore";
const crearTipoDeCliente = ({
    tipo,
    descuento=0,
    }) => {
  
        return addDoc(collection(db, "TiposDeClientes"), {
            nombre:tipo,
            descuento:descuento
        });
        

}
 
export default crearTipoDeCliente;
import { useState, useEffect } from 'react';
import { db } from '../firebase/firebaseConfig';
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';

const useObtenerOrdenes = (startDate, endDate, queryByDay) => {
  const [ordenes, setOrdenes] = useState([]);

  //establecemos la fecha a las 00:00 para que tome todos los pedidos a partir de esa fecha
  const startDay = Number(startDate.setHours(0, 0, 0, 0));
  const EndDay = queryByDay ? Number(startDate.setHours(23, 59, 59, 0)) : Number(endDate.setHours(23, 59, 59, 0))

  useEffect(() => {
    const consulta = query(
      collection(db, 'ordenes'),
      where('fechaUnix', '>=', startDay),
      where('fechaUnix', '<', EndDay),
      orderBy('fechaUnix', 'asc')
    );

    const unsub = onSnapshot(consulta, (snapshot) => {
      const dataSnapShot = snapshot.docs.map((orden) => {
        return {
          idOrden: orden.id,
          cliente: orden.data().cliente,
          descuento: orden.data().descuento,
          fecha: orden.data().fecha,
          formaDePago: orden.data().formDePago,
          productos: orden.data().productos,
          RangoHorario: orden.data().rangoHorario,
          subtotal: orden.data().subTotal,
          total: orden.data().total,
          estado: orden.data().estado,
          fechaOrden: new Date(orden.data().fechaUnix),
          fecchaUnix: orden.data().fechaUnix,
        };
      });
      setOrdenes(dataSnapShot);
    });

    return unsub;
  }, [startDay, EndDay, queryByDay]);
  return { ordenes };
};

export default useObtenerOrdenes;

import { DivTable, TableStyles } from "./style";



const Tabla = ({ children, width="auto",textAlign5="start"}) => {
    
    return (
        <>
            
                <DivTable>
                    <TableStyles width={width} textAlign5={textAlign5}>
                        {children}
                    </TableStyles>
                </DivTable>
               
        </>
    );
}

export default Tabla;
import styled from "styled-components";


const ProveedoresStyle=styled.div`

    width: 90%;
    margin: 1em auto;
    background: #fff;
    border-radius: 15px;

 
`
const DivBuscador=styled.div` 
    width: 50%;
`
const DivTableProveedores=styled.div` 
    width: 90%;
    margin: 1em auto;
    padding: 0 0 2em 0;

`

export {ProveedoresStyle, DivBuscador,DivTableProveedores}
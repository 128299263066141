import { db } from "../firebase/firebaseConfig";
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";



const useObtenerOrdenesPorId = (id) => {
    const [OrdenById, setOrdenById] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {

        if (id !== '') {
            const productoPorId = async () => {
                const documento = await getDoc(doc(db, 'ordenes', id));
                if (documento.exists) {
                    setOrdenById(documento)
                } else {
                    navigate('/usuarios');
                }

            }
            productoPorId();
        }
    }, [id, navigate])

    return [OrdenById];
}

export default useObtenerOrdenesPorId;
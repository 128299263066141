import { db } from "./firebaseConfig";
import { doc, collection, query, getDocs, writeBatch } from "firebase/firestore";


const actualizarOrdenes = async ({ idDoc, estado, productos }) => {
    const batch = writeBatch(db);
    //actualizar estado de la orden
    const sfRef = doc(db, "ordenes", idDoc);
    batch.update(sfRef, {
        estado: estado
    });

    if (estado === 'cancelada') {
        const ref = collection(db, "productos");
        const AllPropductos = query(ref);
        const querySnapshot = await getDocs(AllPropductos);
        const sumCant = new Map()
        productos.forEach(proOrden => {
            sumCant.set(proOrden.idProducto, proOrden.cant)
        });
        querySnapshot.forEach((doc) => {
            if (sumCant.has(doc.id)) {
                console.log({ cant: doc.data().cant, nombre: doc.id })
                sumCant.set(doc.id, sumCant.get(doc.id) + doc.data().cant)
            }
        })
        const ArrayCantModificada = [...sumCant.entries()].map(([idProducto, cant]) => ({ idProducto, cant }))
        console.log(ArrayCantModificada)
        ArrayCantModificada.forEach((cant) => {
            batch.update(doc(db, "productos", cant.idProducto), {
                cant: cant.cant,
            });
        })

    }

    return await batch.commit();

}

export default actualizarOrdenes;
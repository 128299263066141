import { useState } from "react";
import Form from "../../../componentes/form";
import Row from "../../../componentes/Row";
import Column from "../../../componentes/column";
import Button from "../../../componentes/button";
import Modal from "../../../componentes/modal";
import Alert from "../../../componentes/alert";
import crearProveedor from "../../../firebase/crearProveedor";




const ModalAgregarProveedor = ({viewModal, setViewModal}) => {
    
    const [estadoAlert, setEstadoAlert] = useState(false);
    const [mensajeAlert, setMensajeAlert] = useState({});
    const [nombre, setNombre] = useState('');
    const [razonSocial, setRazonSocial] = useState('');
    const [cuit, setCuit] = useState('');
    const [cfIva, setCfIva] = useState('');
    const [correo, setCorreo] = useState('');
    const [telefono, setTelefono] = useState('');
    const [direccion, setDireccion] = useState('');
    const [estado, setEstado] = useState('activo');
    
    



    const registrarProveedor=async(e)=>{
        e.preventDefault();
        const expresionRegular=/[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;
        if(correo!=='' && !expresionRegular.test(correo)){
            setEstadoAlert(true);
            setMensajeAlert({
                mensajeAlert:'Correo no válido',
                type:'error'
            })
            return;
        }
      
       
        if (nombre==='') {
            setEstadoAlert(true);
            setMensajeAlert({
                mensajeAlert:'Nombre no puede quedar vacío',
                type:'error'
            })
           
            return;
        }
       
       
        try {
            
            
            crearProveedor({
                nombre:nombre,
                correo:correo,
                telefono:telefono,
                direccion:direccion,
                estado:estado,
                cuit:cuit,
                cfiva:cfIva,
                razonSocial:razonSocial
            });
            setEstadoAlert(true);
            setMensajeAlert({
                mensajeAlert:'El Proveedor se agregó correctamente',
                type:'success'
            })
            setViewModal(false);
            
        } catch (err) {
            setEstadoAlert(true);

           let mensaje;
            switch (err.code) {
                
                default:
                    mensaje="Ocurrió un error"
                    
                    break;
            }
            setMensajeAlert({
                mensajeAlert:mensaje,
                type:'error'
            })
        }
    }
    return ( 
        <>
        
        <Alert
            mensajeAlert={mensajeAlert.mensajeAlert}
            type={mensajeAlert.type}
            estadoAlert={estadoAlert}
            setEstadoAlert={setEstadoAlert}
            
            ></Alert>
        
        <Modal titulo={"Agregar nuevo Proveedor"} estadoModal={viewModal} cambiarEstado={setViewModal} tamano={40}>
        <Form onSubmit={registrarProveedor}>

            <Row>

                <Column w={90} margin={'auto'}>

                    <label>Nombre</label>
                    <input 
                    type="text" 
                    placeholder="Nombre"
                    value={nombre}
                    onChange={(e)=>{setNombre(e.target.value)}}
                    ></input>
                     <label>Razón Social</label>
        <input 
        type="text" 
        placeholder="Razón Social"
        value={razonSocial}
        onChange={(e)=>{setRazonSocial(e.target.value)}}
        ></input>


        <label>CUIT</label>
        <input 
        type="text" 
        placeholder="CUIT"
        value={cuit}
        onChange={(e)=>{setCuit(e.target.value)}}
        ></input>

        <label>Condición frente al IVA</label>
        <select
        value={cfIva}
        onChange={(e)=>{setCfIva(e.target.value)}}

        >
            <option value={'Responsable Inscripto'} >Responsable Inscripto</option>
            <option value={'Monotributista'}>Monotributista</option>
            <option value={'Sujeto Excento'}>Sujeto Excento</option>
            <option value={'Consumidor Final'}>Consumidor Final</option>
        </select>


                    <label>Correo</label>
                    <input 
                    type="email" 
                    placeholder="Correo"
                    value={correo}
                    onChange={(e)=>{setCorreo(e.target.value)}}
                    ></input>

                    <label>Telefono</label>
                    <input 
                    type="text" 
                    placeholder="Teléfono"
                    value={telefono}
                    onChange={(e)=>{setTelefono(e.target.value)}}
                    ></input>

                    <label>Dirección</label>
                    <input 
                    type="text" 
                    placeholder="Direccion"
                    value={direccion}
                    onChange={(e)=>{setDireccion(e.target.value)}}
                    ></input>

                    <label>Estado</label>
                  <select
                  value={estado}
                  onChange={(e)=>{setEstado(e.target.value)}}
                  
                  >
                      <option value={'activo'} >Activo</option>
                      <option value={'inactivo'}>Inactivo</option>
                  </select>
                   
                  

                    <Button size={'smallBlock'} margin={'2em 0'}>Registrar</Button>
                </Column>


            </Row>

        </Form>
    </Modal>
    </>
     );
}
 
export default ModalAgregarProveedor;
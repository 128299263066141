import styled from "styled-components";



const OrdenesStyle = styled.div`

    width: 90%;
    margin: 1em auto;
    background: #fff;
    border-radius: 15px;
    display: flex;
    flex-direction: column;

`
const ThumbOrdenes = styled.div` 
    width: 95%;
    margin: 1em auto;
    

`
const DateContainer = styled.div`

margin-left: 1em;
padding: 1em;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
gap: 1em;
`
const ButtonDate = styled.button`
background: transparent;
font-size: 0.7em;
border: none;
color: var(--primary);
font-weight: bold;
margin-left: 1.5em;
cursor: pointer;
:hover{
    color: var(--primaryHover);
}
`


export { OrdenesStyle, ThumbOrdenes, DateContainer, ButtonDate }
import { useState, useEffect } from "react";
import Form from "../../../../componentes/form";
import Row from "../../../../componentes/Row";
import Column from "../../../../componentes/column";
import Button from "../../../../componentes/button";
import Modal from "../../../../componentes/modal";
import Alert from "../../../../componentes/alert";
import useObtenerTiposDeClientePorId from "../../../../hooks/useObtenerTipoDeClientePorId";
import actualizarTiposDeClientes from "../../../../firebase/actualizarTiposDeClientes";


const ModalEditar = ({ viewModalEditar, setViewModalEditar, idEditar }) => {

    const [tiposDeClientesPorId] = useObtenerTiposDeClientePorId(idEditar);
    const [estadoAlert, setEstadoAlert] = useState(false);
    const [mensajeAlert, setMensajeAlert] = useState({});
    const [tipo, setTipo] = useState('');
    const [descuento, setDescuento] = useState('');



    useEffect(() => {

        if (tiposDeClientesPorId && tiposDeClientesPorId.length !== 0) {


            setTipo(tiposDeClientesPorId.data().nombre);
            setDescuento(tiposDeClientesPorId.data().descuento);


        }



    }, [tiposDeClientesPorId, idEditar]);


    const editar = async (e) => {
        e.preventDefault();


        if (tipo === '') {
            setEstadoAlert(true);
            setMensajeAlert({
                mensajeAlert: 'Tipo no puede quedar vacío',
                type: 'error'
            })

            return;
        }


        try {
            await actualizarTiposDeClientes({
                idDoc:idEditar,
                tipo: tipo,
                descuento: descuento

            })

            setEstadoAlert(true);
            setMensajeAlert({
                mensajeAlert: 'El Proveedor se editó correctamente',
                type: 'success'
            })
            setViewModalEditar(false);

        } catch (err) {
            setEstadoAlert(true);
            console.log(err);
            let mensaje;
            switch (err.code) {

                default:
                    mensaje = "Ocurrió un error"

                    break;
            }
            setMensajeAlert({
                mensajeAlert: mensaje,
                type: 'error'
            })
        }
    }

    return (
        <>

            <Alert
                mensajeAlert={mensajeAlert.mensajeAlert}
                type={mensajeAlert.type}
                estadoAlert={estadoAlert}
                setEstadoAlert={setEstadoAlert}

            ></Alert>

            <Modal titulo={"Editar Tipo de Cliente"} estadoModal={viewModalEditar} cambiarEstado={setViewModalEditar} tamano={40}>
                <Form onSubmit={editar}>

                    <Row>

                        <Column w={90} margin={'auto'}>

                            <label>Tipo</label>
                            <input
                                type="text"
                                placeholder="Nombre"
                                value={tipo}
                                onChange={(e) => { setTipo(e.target.value) }}
                            ></input>

                            <label>Descuento</label>
                            <input
                                type="text"
                                placeholder="Descuento"
                                value={descuento}
                                onChange={(e) => { setDescuento(e.target.value) }}
                            ></input>


                            <Button size={'smallBlock'} margin={'2em 0'}>Modificar</Button>
                        </Column>


                    </Row>

                </Form>
            </Modal>
        </>
    );
}

export default ModalEditar;
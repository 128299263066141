import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase/firebaseConfig";
import {onAuthStateChanged } from "firebase/auth";
import useObtenerUsuarios from "../hooks/useObtenerUsuarios";

const contextSession= React.createContext();


const useAuth=()=>{
    return useContext(contextSession);
}

const ProviderSession = ({children}) => {
    //iniciamos estado para el usuario de auth FIREBASE
    const [user,setUser]=useState();
    //inciamos estado para luego rellenar con los datos del usuario de la coleccion "usuarios"
    const [dataUser,setDatauser]=useState({});
    const [cargando,setCargando]=useState(true);
    //listamos todos los usuarios registrados
    const [usuarios]= useObtenerUsuarios()
    
    useEffect(()=>{
        const cancelarSuscripcion= onAuthStateChanged(auth,(user)=>{
            setUser(user);
            if (usuarios.data.length>0 && user !==null) {
                
                const OtherDataUser=usuarios.data.filter((u)=>{
                    
                    return u[0]===user.uid
                })
                
                if (OtherDataUser.length>0) {
                    setDatauser({
                        nombre:OtherDataUser[0][1],
                        tipoUser:OtherDataUser[0][3],
                        estadoUser:OtherDataUser[0][4]
                    })
            }
            }
             setCargando(false)
             return cancelarSuscripcion;
        })
    },[usuarios.data]);
    return ( 
        <contextSession.Provider value={{user:user,datauser:dataUser}}>
            {!cargando && children}
        </contextSession.Provider>
     );
}
 
export {contextSession,ProviderSession, useAuth};
import React, {useContext} from 'react';
import Header from "../header";
import MenuPrincipal from "../menuPrincipal";
import { PageDiv } from "./style";
import { contextSession } from '../../context/contextSession';

const System = ({children}) => {
    const {datauser}=useContext(contextSession)
    
    
    return (
        <>
        {datauser.tipoUser !=='playa' ?
                <>
                <Header></Header>
                <MenuPrincipal></MenuPrincipal>
               
                <PageDiv w={82}>
                    {children}
                </PageDiv>
                </>
                 :<>
                 <Header></Header>
                <PageDiv w={100}>
                    {children}
                </PageDiv>
                 
                 </>
            }
        </>

    );
}

export default System;
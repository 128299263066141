import styled from "styled-components";


const ContentModalOrdenes=styled.div`
    width: 100%;
    select{
        width: 50%;
        height: 30px ;
    }
`
const RowModalOrdenes=styled.div`
    width: 100%;
    display: flex;
    flex-direction:row;
    justify-content:space-around;
    align-items:center;
`


const DetalleOrden=styled.div`
    width: 90%;
    height: 35vh;
    margin: 1em auto;
    overflow-y:auto;

`

export {ContentModalOrdenes, RowModalOrdenes, DetalleOrden}
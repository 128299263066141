import { db } from "../firebase/firebaseConfig";
import { useEffect, useState } from "react";
import {doc, getDoc} from "firebase/firestore";
import { useNavigate } from "react-router-dom";



const useObtenerTiposDeClientePorId = (id) => {
    const [TiposDeclientesPorID, setTiposDeClientesPorId]=useState([]);
    const navigate=useNavigate();
   
    useEffect(()=>{
        
        if (id!=='') {
            const tiposDeclientesPorID=async()=>{
                const documento= await getDoc(doc(db,'TiposDeClientes',id));
                if (documento.exists) {
                    setTiposDeClientesPorId(documento)
                } else {
                    navigate('/clientes/tipos');
                }
                
            }
            tiposDeclientesPorID();
        } 
        },[id,navigate])
        
    return [TiposDeclientesPorID];
}
 
export default useObtenerTiposDeClientePorId;
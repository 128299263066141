import styled from 'styled-components'


const StockStyle=styled.div`

    width: 90%;
    margin: 1em auto;
    background: #fff;
    border-radius: 15px;


`

const SelectStock=styled.div`
    width: 40%;
    display: flex;
    flex-direction:column;
    margin-left:1em;
    h5{
        font-weight:600;
        margin-bottom:0em;
    }
    select{
        width: 100%;
        height: 30px;
    }
` 

const DivTableStock=styled.div` 
    width: 90%;
    margin: 1em auto;
    padding: 0 0 2em 0;

`
const DivBuscador=styled.div` 
    width: 50%;
    margin: 2em auto 0em auto;
    
`

export {StockStyle,SelectStock,DivTableStock,DivBuscador}
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import GlobalStyle from "./theme/GlobalStyle"
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'
import {ProviderSession} from './context/contextSession'
import {ProviderCaja} from './context/contextCaja'

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle/>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ProviderSession>
        <ProviderCaja>
          <App />
        </ProviderCaja>
      </ProviderSession>
    </MuiPickersUtilsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);



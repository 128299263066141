import { DivDatosCliente } from "./style";


const DatosCliente = ({data}) => {

    const buscarTipo=(tipoDePrecio)=>{
        let tipoDeCliente=null;
        switch (tipoDePrecio) {
            case "precioBase":
                tipoDeCliente="Cliente Final"
                break;
            case "precioCliente":
                tipoDeCliente="Cliente";
                break;
            case "precioCorralon":
                tipoDeCliente="Corralón";
                break;
            case "precioFerreteria":
                tipoDeCliente="Ferretería";
                break;
            default:
                tipoDeCliente="Cliente Final"
                break;
        }
        return tipoDeCliente;
    }
    return (
        <DivDatosCliente>      
            <p><span>Nombre: </span>{data.nombre}</p>
            <p><span>Correo:</span>{data.correo}</p>
            <p><span>Teléfono:</span>{data.telefono}</p>
            <p><span>Dirección:</span>{data.direccion}</p>
            <p><span>Tipo:</span>{buscarTipo(data.tipoDePrecio)}</p>
            <p><span>C.F. IVA:</span>{data.cfiva}</p>
        </DivDatosCliente>
    );
}

export default DatosCliente;
import { useEffect, useState } from "react";
import Form from "../../../componentes/form";
import Row from "../../../componentes/Row";
import Column from "../../../componentes/column";
import Button from "../../../componentes/button";
import Modal from "../../../componentes/modal";
import Alert from "../../../componentes/alert";
import crearProducto from "../../../firebase/crearProducto";
import useObtenerStock from "../../../hooks/useObenterStock";
import { DivAvisoCantidadEnAlmacen } from "../style";

const ModalAgregarProductos = ({ viewModal, setViewModal }) => {
  const [stock] = useObtenerStock();
  const [estadoAlert, setEstadoAlert] = useState(false);
  const [mensajeAlert, setMensajeAlert] = useState({});
  const [nombre, setNombre] = useState("");
  const [cant, setCant] = useState(1);
  const [cantMin, setCantMin] = useState(1);
  const [presentacion, setPresentacion] = useState("");
  const [cantPorUnidad, setCantPorUnidad] = useState(1);
  const [codigo, setCodigo] = useState("");
  const [OrigenStockList, setOrigenStock] = useState([]);
  const [origenData, setOrigenData] = useState([]);
  const [almacenMax, setAlmacenMax] = useState(1);
  const [precioBase, setPrecioBase] = useState(0);
  const [precioCliente, setPrecioCliente] = useState(0);
  const [precioCorralon, setPrecioCorralon] = useState(0);
  const [precioFerreteria, setPrecioFerreteria] = useState(0);

  useEffect(() => {
    setPresentacion("bolsa");
    setNombre("");
    setCodigo("");
    setCant(1);
    setCantMin(1);
    setCantPorUnidad(1);
    setOrigenStock([]);
    setOrigenData([]);
  }, [viewModal]);

  const ProductoMax = Math.floor(almacenMax / cantPorUnidad) - cant;

  const getOrigenData = (valorSelect) => {
    if (valorSelect !== "") {
      const [arrayResult] = stock.filter((result) => {
        return result[0] === valorSelect;
      });

      setOrigenData(arrayResult);
      setAlmacenMax(
        (parseFloat(arrayResult[5]) * parseFloat(arrayResult[9])).toFixed(5)
      );
    }
  };

  const buscarOrigenList = (valorSelect) => {
    if (valorSelect !== "") {
      const arrayResult = stock.filter((result) => {
        return result[3] === valorSelect;
      });
      setOrigenStock(arrayResult);

      if (arrayResult.length > 0) {
        getOrigenData(arrayResult[0][0]);
      } else if (arrayResult.length === 0) {
        setOrigenData([]);
      }
    }
  };

  const registrarProducto = async (e) => {
    e.preventDefault();

    if (origenData.length === 0) {
      setEstadoAlert(true);
      setMensajeAlert({
        mensajeAlert: "Elige un Origen Stock",
        type: "error",
      });

      return;
    }
    if (
      nombre === "" ||
      cant === "" ||
      cantMin === "" ||
      codigo === "" ||
      cantPorUnidad === ""
    ) {
      setEstadoAlert(true);
      setMensajeAlert({
        mensajeAlert: "Por favor rellena todos los campos",
        type: "error",
      });

      return;
    }
    const expresionRegular = /^[0-9]+$/;
    if (!expresionRegular.test(cant)) {
      setEstadoAlert(true);
      setMensajeAlert({
        mensajeAlert: "Stock debe ser un numero entero positivo",
        type: "error",
      });
      return;
    }
    if (!expresionRegular.test(cantMin)) {
      setEstadoAlert(true);
      setMensajeAlert({
        mensajeAlert: "Stock Min debe ser un numero entero positivo",
        type: "error",
      });
      return;
    }


    if (Number(ProductoMax) < 0) {
      setEstadoAlert(true);
      setMensajeAlert({
        mensajeAlert: "Te excediste en el máximo permitido de almacén",
        type: "error",
      });
      return;
    }
    const stockRestanteTotal = parseFloat(
      almacenMax - cantPorUnidad * cant
    ).toFixed(2); //eje:800kg - 20kg * 10unidades
    const stockRestanteUnidades = Number(
      stockRestanteTotal / parseFloat(origenData[9])
    );

    try {
      crearProducto({
        nombre: nombre,
        cant: parseInt(cant, 10),
        cantMin: parseInt(cantMin, 10),
        presentacion: presentacion,
        cantXUnidad: cantPorUnidad,
        idOrigen: origenData[0],
        codigo: codigo,
        stockRestanteUnidades: stockRestanteUnidades,
        stockRestanteTotal: stockRestanteTotal,
        precioBase: parseFloat(precioBase),
        precioCliente: parseFloat(precioCliente),
        precioCorralon: parseFloat(precioCorralon),
        precioFerreteria: parseFloat(precioFerreteria),
      });
      setEstadoAlert(true);
      setMensajeAlert({
        mensajeAlert: "El Producto se agregó correctamente",
        type: "success",
      });
      setViewModal(false);
    } catch (err) {
      setEstadoAlert(true);

      let mensaje;
      switch (err.code) {
        default:
          mensaje = "Ocurrió un error";
          break;
      }
      setMensajeAlert({
        mensajeAlert: mensaje,
        type: "error",
      });
    }
  };
  return (
    <>
      <Alert
        mensajeAlert={mensajeAlert.mensajeAlert}
        type={mensajeAlert.type}
        estadoAlert={estadoAlert}
        setEstadoAlert={setEstadoAlert}
      ></Alert>

      <Modal
        titulo={"Agregar nuevo Producto"}
        estadoModal={viewModal}
        cambiarEstado={setViewModal}
        tamano={40}
      >
        <Form onSubmit={registrarProducto}>
          <Row>
            <Column w={90}>
              <h5>Origen del Producto</h5>
              <label>Tipo de Origen</label>
              <select
                onChange={(e) => {
                  buscarOrigenList(e.target.value);
                }}
              >
                <option value={"aridos"}>Áridos</option>
                <option value={"hierros"}>Hierros</option>
                <option value={"cales"}>Cales</option>
                <option value={"ladrillos"}>Ladrillos</option>
                <option value={"ceramicas"}>Cerámicas</option>
                <option value={"porcelanato"}>Porcelanato</option>
                <option value={"cementos"}>Cementos</option>
                <option value={"aditivos"}>Aditivos</option>
                <option value={"revestimientos"}>Revestimientos</option>
                <option value={"pegamentos"}>Pegamentos</option>
                <option value={"Baño"}>Baño</option>
                <option value={"yesos"}>Yesos</option>
                <option value={"Herramientas"}>Herramientas</option>
                <option value={"Accesorios"}>Accesorios</option>
              </select>
              <label>Origen Stock</label>
              <select
                onChange={(e) => {
                  getOrigenData(e.target.value);
                }}
              >
                {OrigenStockList.map((dataOrigen) => {
                  return (
                    <option key={dataOrigen[0]} value={dataOrigen[0]}>
                      {dataOrigen[1]}
                    </option>
                  );
                })}
              </select>
            </Column>
          </Row>
          <hr></hr>
          <Row>
            <Column w={90}>
              <h5>Datos del Producto</h5>

              <label>Código</label>
              <input
                type="text"
                placeholder="Nombre"
                value={codigo}
                onChange={(e) => {
                  setCodigo(e.target.value);
                }}
              ></input>
              <label>Nombre del Producto</label>
              <input
                type="text"
                placeholder="Nombre"
                value={nombre}
                onChange={(e) => {
                  setNombre(e.target.value);
                }}
              ></input>
              <label>Precio Cliente Final</label>
              <input
                type="number"
                placeholder="Precio"
                value={precioBase}
                onChange={(e) => {
                  setPrecioBase(e.target.value ? e.target.value : 0);
                }}
              ></input>

              <label>Precio Cliente </label>
              <input
                type="number"
                placeholder="Precio Cliente"
                value={precioCliente}
                onChange={(e) => {
                  setPrecioCliente(e.target.value ? e.target.value : 0);
                }}
              ></input>
              <label>Precio Corralón</label>
              <input
                type="number"
                placeholder="Precio Corralón"
                value={precioCorralon}
                onChange={(e) => {
                  setPrecioCorralon(e.target.value ? e.target.value : 0);
                }}
              ></input>
              <label>Precio Ferretería </label>
              <input
                type="number"
                placeholder="Precio Ferretería"
                value={precioFerreteria}
                onChange={(e) => {
                  setPrecioFerreteria(e.target.value ? e.target.value : 0);
                }}
              ></input>
              <label>Presentación</label>
              <select
                value={presentacion}
                onChange={(e) => {
                  setPresentacion(e.target.value);
                }}
              >
                <option value={"bolsas"}>Bolsas</option>
                <option value={"cajas"}>Cajas</option>
                <option value={"paquetes"}>Paquetes</option>
                <option value={"Tachos"}>Tachos</option>
                <option value={"unidades"}>Unidades</option>
              </select>
            </Column>
          </Row>
          <hr></hr>
          <Row>
            <Column w={90}>
              <h5>Proporcion de Producto</h5>
              {origenData.length > 0 ? (
                <DivAvisoCantidadEnAlmacen>
                  <p>Cantidad disponible en Almacén:</p>
                  <p>
                    {origenData[5]} {origenData[4]}(s) de {origenData[9]}{" "}
                    {origenData[8]} ({almacenMax} {origenData[8]} )
                  </p>
                </DivAvisoCantidadEnAlmacen>
              ) : (
                <></>
              )}
              <label>
                ¿Cuánt@s {origenData[8]} va a tener cada{" "}
                <strong>{nombre}</strong>?{" "}
              </label>
              <input
                pattern="^[0-9.]*$"
                type="text"
                placeholder="Cantidad"
                value={cantPorUnidad}
                onChange={(e) => {
                  setCantPorUnidad(e.target.value);
                }}
                min={1}
              ></input>

              <label>
                Stock
                {ProductoMax >= 0 ? (
                  <small style={{ marginLeft: "0.5em" }}>
                    (te quedan {ProductoMax} {presentacion})
                  </small>
                ) : (
                  <small style={{ marginLeft: "0.5em", color: "red" }}>
                    (ya no quedan {presentacion}(s))
                  </small>
                )}
              </label>
              <input
                type="number"
                placeholder="Stock"
                value={cant}
                onChange={(e) => {
                  setCant(e.target.value);
                }}
                min={0}
              ></input>
              <label>Stock Mínimo</label>
              <input
                type="number"
                placeholder="Stock Mínimo"
                value={cantMin}
                onChange={(e) => {
                  setCantMin(e.target.value);
                }}
                min={0}
              ></input>

              <Button size={"smallBlock"} margin={"2em 0"}>
                Registrar
              </Button>
            </Column>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ModalAgregarProductos;

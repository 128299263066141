import {
  ProductStyle,
  DivTableProduct,
  DivBuscador,
  PDFButton,
} from "./style.js";
import Button from "../../componentes/button/index";
import Row from "../../componentes/Row/index.js";
import System from "../../componentes/sistema/index.js";
import { useEffect, useState, useRef } from "react";
import Buscador from "../../componentes/buscador/index.js";

import useObtenerProductos from "../../hooks/useObtenerProductos.js";
import useObtenerStock from "../../hooks/useObenterStock.js";
import Tabla from "../../componentes/tabla/index.js";
import ModalAgregarProductos from "./modalAgregarProductos/index.js";
import ModalEditarProductos from "./modalEditarProductos/index.js";
import desactivarProducto from "../../firebase/desactivarProductos.js";
import CellEdit from "../../componentes/CellEdit/index.js";
import ReactToPrint from "react-to-print";
import ExcelExport from "../../componentes/ExcelExport/index.js";
import PdfIcon from "../../componentes/icons/pdf";
const Productos = () => {
  const componentRef = useRef();
  const [viewModal, setViewModal] = useState(false);
  const [viewModalEditar, setViewModalEditar] = useState(false);
  const [idEditar, SetidEditar] = useState("");
  const [dataTabla, setDataTabla] = useState([]);
  const [dataTablaStatic, setDataTablaStactic] = useState([]);
  const [productos] = useObtenerProductos();
  const [stock] = useObtenerStock();

  useEffect(() => {
    const productosNew = productos.map((cadaProducto) => {
      let marca;
      let tipo;
      let unidad;

      for (let i = 0; i < stock.length; i++) {
        if (stock[i].includes(cadaProducto[5])) {
          marca = stock[i][2];
          tipo = stock[i][3];
          unidad = stock[i][8];
        }
      }
      return [...cadaProducto, marca, tipo, unidad];
    });
    setDataTabla(productosNew);
    setDataTablaStactic(productosNew);
  }, [productos, stock]);

  const modalEditar = (id) => {
    SetidEditar(id);
    setViewModalEditar(true);
  };

  return (
    <System>
      <ModalEditarProductos
        viewModal={viewModalEditar}
        setViewModal={setViewModalEditar}
        idEditar={idEditar}
      ></ModalEditarProductos>
      <ModalAgregarProductos
        viewModal={viewModal}
        setViewModal={setViewModal}
      ></ModalAgregarProductos>
      <ProductStyle>
        <Row
          justifyContent={"space-between"}
          alignItems={"center"}
          w={95}
          margin={"auto"}
        >
          <div>
            {productos.length > 0 && (
              <>
                <ReactToPrint
                  trigger={() => (
                    <PDFButton margin={0}>
                      <PdfIcon width={"30px"} />
                    </PDFButton>
                  )}
                  content={() => componentRef.current}
                />
                <ExcelExport
                  excelData={productos.map((item) => {
                    return {
                      COD: item[8],
                      nombre: item[1],
                      consumidorFinal: item[9],
                      cliente: item[10],
                      corralon: item[11],
                      ferreteria: item[12],
                    };
                  })}
                  fileName={"productos"}
                />
              </>
            )}
          </div>
          <Button
            onClick={() => {
              setViewModal(true);
            }}
            margin={"10px"}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg>
            Agregar
          </Button>
        </Row>
        <DivBuscador>
          <Buscador
            setDataTabla={setDataTabla}
            dataTablaStatic={dataTablaStatic}
          ></Buscador>
        </DivBuscador>
        <DivTableProduct ref={componentRef}>
          <Tabla>
            {dataTabla.length > 0 ? (
              <>
                <thead>
                  <tr>
                    <th>COD </th>
                    <th>Nombre </th>
                    <th>Stock</th>
                    <th>Precio C. Final ($)</th>
                    <th>Precio Cliente ($)</th>
                    <th>Precio Corralón ($)</th>
                    <th>Precio Ferretería ($)</th>

                    <th>Acciones</th>
                  </tr>
                </thead>

                <tbody>
                  {dataTabla.map((productElement) => {
                    return (
                      <tr key={productElement[0]}>
                        <td>{productElement[8]} </td>
                        <td>{productElement[1]} </td>
                        <td>
                          <div
                            style={{
                              background:
                                productElement[2] < productElement[3]
                                  ? "#f3b6b6"
                                  : "transparent",
                              padding: "0.5em",
                              borderRadius: "0.5em",
                              fontWeight: "bold",
                            }}
                          >
                            {productElement[2]}
                          </div>
                        </td>
                        <td>
                          <CellEdit
                            value={productElement[9]}
                            idDoc={productElement[0]}
                            cellName={"precioBase"}
                            collection="productos"
                          />
                        </td>
                        <td>
                          <CellEdit
                            value={productElement[10]}
                            idDoc={productElement[0]}
                            cellName={"precioCliente"}
                            collection="productos"
                          />
                        </td>
                        <td>
                          <CellEdit
                            value={productElement[11]}
                            idDoc={productElement[0]}
                            cellName={"precioCorralon"}
                            collection="productos"
                          />
                        </td>
                        <td>
                          <CellEdit
                            value={productElement[12]}
                            idDoc={productElement[0]}
                            cellName={"precioFerreteria"}
                            collection="productos"
                          />
                        </td>

                        <td>
                          <div>
                            <button
                              onClick={() => {
                                modalEditar(productElement[0]);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"></path>
                              </svg>
                            </button>

                            <button
                              onClick={() => {
                                desactivarProducto({
                                  idDoc: productElement[0],
                                  estado: "inactivo",
                                });
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                              >
                                <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"></path>
                              </svg>
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </>
            ) : (
              <></>
            )}
          </Tabla>
        </DivTableProduct>
      </ProductStyle>
    </System>
  );
};

export default Productos;

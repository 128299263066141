import styled from "styled-components";


const CajaStyle=styled.div`

    width: 90%;
    margin: 1em auto;
    background: #fff;
    border-radius: 15px;

`
const SectionProductos=styled.div`
    width: 95%;
    margin: 2em;

`
const SectionDataCliente=styled.div`
    width: 70%;
    margin: 2em;

`
const DivProductos=styled.div`
    width: 100%;
    height: 55vh;
    margin: 1em auto;
    overflow-y:auto;
    

`

const SectionDatosOrden=styled.div`
    width: 100%;
    display:flex;
    justify-content:end;
    align-items:end;
    padding: 1em;
    
`

const SectionButtonProcesar=styled.div`
    margin: auto;
    padding: 2em;
    width: 50%;
`





export {CajaStyle,SectionDataCliente,SectionProductos,DivProductos,SectionDatosOrden,SectionButtonProcesar}
import { db } from "./firebaseConfig";
import { doc, updateDoc } from "firebase/firestore";


const actualizarTipodeCliente = async ({
    idDoc,
    tipo,
    descuento,
   
}) => {
    const documento = doc(db, "TiposDeClientes", idDoc);

    return await updateDoc(documento, {
    nombre:tipo,
    descuento:descuento,
    });
}

export default actualizarTipodeCliente;
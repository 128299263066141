import { StockStyle, DivTableStock, DivBuscador } from "./style.js";
import Button from "../../componentes/button/index";
import Row from "../../componentes/Row/index.js";
import System from "../../componentes/sistema/index.js";
import Tabla from "../../componentes/tabla/index.js";
import { useEffect, useState } from "react";
import Buscador from "../../componentes/buscador/index.js";
import useObtenerStock from "../../hooks/useObenterStock.js";
import ModalAgregarStock from "./modalAgregarStock/index.js";
import ModalEditarStock from "./modalEditar/index.js";
import borrarStock from "../../firebase/borrarStock.js";
import useObtenerProveedores from "../../hooks/useObtenerProveedores.js";

const Stock = () => {
  const [stock] = useObtenerStock();
  const [proveedores] = useObtenerProveedores();
  const [viewModal, setViewModal] = useState(false);
  const [idEditar, SetidEditar] = useState("");
  const [viewModalEditar, setViewModalEditar] = useState(false);
  const [dataTabla, setdataTabla] = useState([]);
  const [dataTablaStatic, setdataTablaStatic] = useState([]);

  useEffect(() => {
    const stockNew = stock.map((cadaStock) => {
      let nombre;
      for (let i = 0; i < proveedores.length; i++) {
        if (proveedores[i].includes(cadaStock[7])) {
          nombre = proveedores[i][1];
        }
      }
      return [...cadaStock, nombre];
    });

    setdataTabla(stockNew);
    setdataTablaStatic(stockNew);
  }, [stock, proveedores]);

  const borrar = (id) => {
    borrarStock(id);
  };

  const modalEditar = (id) => {
    SetidEditar(id);
    setViewModalEditar(true);
  };
  return (
    <>
      <ModalEditarStock
        viewModalEditar={viewModalEditar}
        setViewModalEditar={setViewModalEditar}
        idEditar={idEditar}
      ></ModalEditarStock>
      <ModalAgregarStock
        viewModal={viewModal}
        setViewModal={setViewModal}
      ></ModalAgregarStock>
      <System>
        <StockStyle>
          <Row justifyContent={"end"} alignItems={"end"} w={95} margin={"auto"}>
            <Button
              onClick={() => {
                setViewModal(true);
              }}
              margin={"10px"}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
              </svg>
              Agregar Nuevo
            </Button>
          </Row>
          <DivBuscador>
            <Buscador
              setDataTabla={setdataTabla}
              dataTablaStatic={dataTablaStatic}
            ></Buscador>
          </DivBuscador>
          <DivTableStock>
            <Tabla>
              {dataTabla.length > 0 ? (
                <>
                  <thead>
                    <tr>
                      <th>Nombre </th>
                      <th>Marca </th>
                      <th>Tipo</th>
                      <th>Presentación</th>
                      <th>Stock</th>

                      <th>Acciones </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataTabla.map((stockElement) => {
                      return (
                        <tr key={stockElement[0]}>
                          <td>{stockElement[1]} </td>
                          <td>{stockElement[2]}</td>

                          <td>{stockElement[3]} </td>
                          <td>{stockElement[4]} </td>
                          <td>
                            <div
                              style={{
                                background:
                                  stockElement[5] < stockElement[6]
                                    ? "#f3b6b6"
                                    : "transparent",
                                padding: "0.5em",
                                borderRadius: "0.5em",
                                fontWeight: "bold",
                              }}
                            >
                              {stockElement[5]}
                            </div>{" "}
                          </td>

                          <td>
                            <div>
                              <button
                                onClick={() => {
                                  modalEditar(stockElement[0]);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"></path>
                                </svg>
                              </button>

                              <button
                                onClick={() => {
                                  borrar(stockElement[0]);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"></path>
                                </svg>
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </>
              ) : (
                <></>
              )}
            </Tabla>
          </DivTableStock>
        </StockStyle>
      </System>
    </>
  );
};

export default Stock;

import {
  CajaStyle,
  SectionDataCliente,
  SectionProductos,
  SectionDatosOrden,
  DivProductos,
  SectionButtonProcesar,
} from "./style";
import DatosCliente from "../../componentes/datosCliente";
import DatosOrden from "../../componentes/datosOrden";
import DatosTotales from "../../componentes/datosTotal";
import Row from "../../componentes/Row";
import Column from "../../componentes/column";
import Button from "../../componentes/button";
import System from "../../componentes/sistema";
import { useState, useContext } from "react";
import ModalClientesCaja from "./modalCliente";
import ModalProductosCaja from "./modalProductos";
import Tabla from "../../componentes/tabla";
import ItemProductoCaja from "./itemProducto";
import { contextCaja } from "../../context/contextCaja";
import Alert from "../../componentes/alert";
import crearOrden from "../../firebase/crearOrdenes";

const Caja = () => {
  const [viewModal, setViewModal] = useState(false);
  const [viewModalProductos, setViewModalProductos] = useState(false);
  const {
    productos,
    cliente,
    fecha,
    formDePago,
    rangoHorario,
    subTotal,
    descuento,
    total,
    resetCaja,
  } = useContext(contextCaja);
  const [estadoAlert, setEstadoAlert] = useState(false);
  const [mensajeAlert, setMensajeAlert] = useState({});

  const newOrder = async () => {
    if (cliente.id === "") {
      setEstadoAlert(true);
      setMensajeAlert({
        mensajeAlert: "Por favor añade un Cliente a la Orden",
        type: "error",
      });
      return;
    }
    if (productos.length < 1) {
      setEstadoAlert(true);
      setMensajeAlert({
        mensajeAlert: "Por favor añade productos a la Orden",
        type: "error",
      });
      return;
    }

    try {
      crearOrden({
        cliente,
        productos,
        fecha,
        formDePago,
        rangoHorario,
        subTotal,
        descuento,
        total,
      });

      setEstadoAlert(true);
      setMensajeAlert({
        mensajeAlert: "La Orden se agregó correctamente",
        type: "success",
      });

      resetCaja();
    } catch (err) {
      setEstadoAlert(true);

      let mensaje;
      switch (err.code) {
        default:
          mensaje = "Ocurrió un error";

          break;
      }
      setMensajeAlert({
        mensajeAlert: mensaje,
        type: "error",
      });
    }
  };

  return (
    <>
      <Alert
        mensajeAlert={mensajeAlert.mensajeAlert}
        type={mensajeAlert.type}
        estadoAlert={estadoAlert}
        setEstadoAlert={setEstadoAlert}
      ></Alert>

      <ModalClientesCaja
        viewModal={viewModal}
        setViewModal={setViewModal}
      ></ModalClientesCaja>
      <ModalProductosCaja
        viewModal={viewModalProductos}
        setViewModal={setViewModalProductos}
      ></ModalProductosCaja>

      <System>
        <CajaStyle>
          <Row w={100}>
            <Column w={70}>
              <SectionDataCliente>
                <h4>Datos del Cliente</h4>
                <Button
                  margin={"0 0 5px 0 "}
                  onClick={() => {
                    setViewModal(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-person-plus-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                    <path
                      fillRule="evenodd"
                      d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                    />
                  </svg>
                  Agregar Cliente
                </Button>
                <DatosCliente data={cliente}></DatosCliente>
              </SectionDataCliente>
              <SectionProductos>
                <Button
                  margin={"0 0 5px 0"}
                  onClick={() => {
                    setViewModalProductos(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                    />
                  </svg>
                  Agregar producto
                </Button>
                <DivProductos>
                  {productos.length > 0 ? (
                    <Tabla textAlign5={"center"}>
                      <>
                        <thead>
                          <tr>
                            <th>COD </th>
                            <th>Nombre </th>
                            <th>P. Unit</th>
                            <th>P. Total </th>
                            <th>Cant </th>
                            <th>Acciones </th>
                          </tr>
                        </thead>
                        <tbody>
                          {productos.map((productoItem) => {
                            return (
                              <ItemProductoCaja
                                key={productoItem.idProducto}
                                productoItem={productoItem}
                              ></ItemProductoCaja>
                            );
                          })}
                        </tbody>
                      </>
                    </Tabla>
                  ) : (
                    <></>
                  )}
                </DivProductos>
              </SectionProductos>
            </Column>
            <Column w={20}>
              <SectionDatosOrden>
                <DatosOrden
                //    los datos de orden los toam del contexto caja
                ></DatosOrden>
              </SectionDatosOrden>
              <DatosTotales
              // los datos de totales los toma del contexto caja
              ></DatosTotales>
              <SectionButtonProcesar>
                <Button
                  size={"mediumBlock"}
                  onClick={() => {
                    newOrder();
                  }}
                >
                  Procesar
                </Button>
              </SectionButtonProcesar>
            </Column>
          </Row>
        </CajaStyle>
      </System>
    </>
  );
};

export default Caja;

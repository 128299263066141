
import { ContentModalOrdenes, DetalleOrden } from './style'
import Modal from '../../../componentes/modal';
import Row from '../../../componentes/Row';
import Column from '../../../componentes/column';
import useObtenerOrdenesPorId from '../../../hooks/useObtenerOrdenporID';
import { DivDatosCliente } from '../../../componentes/datosCliente/style';
import { useEffect, useState } from 'react';
import Tabla from '../../../componentes/tabla';
import Button from '../../../componentes/button';
import actualizarOrdenes from '../../../firebase/actualizarOrdenes';
import Alert from '../../../componentes/alert';

const ModalEditarOrden = ({ viewModal, setViewModal, idEditar }) => {
    const [orden] = useObtenerOrdenesPorId(idEditar)
    const [Nombre, setNombre] = useState('')
    const [Correo, setCorreo] = useState('')
    const [Telefono, setTelefono] = useState('')
    const [Direccion, setDireccion] = useState('')

    const [productos, setProductos] = useState([])
    const [fecha, setFecha] = useState('')
    const [formDePago, setFormaDePago] = useState('')
    const [rangoHorario, setRangoHorario] = useState('')
   

    const [Estado, setEstado] = useState('')
    const [estadoAlert, setEstadoAlert] = useState(false);
    const [mensajeAlert, setMensajeAlert] = useState({});
    


    useEffect(() => {
        if (orden && orden.length !== 0) {
            setNombre(orden.data().cliente.nombre)
            setCorreo(orden.data().cliente.correo)
            setTelefono(orden.data().cliente.telefono)
            setDireccion(orden.data().cliente.direccion)
            
            setProductos(orden.data().productos)
            setFecha(`${orden.data().fecha.toDate().getDate()}/${orden.data().fecha.toDate().getMonth()}/${orden.data().fecha.toDate().getFullYear()}`)
            setFormaDePago(orden.data().formDePago)
            setRangoHorario(orden.data().rangoHorario)
           
            setEstado(orden.data().estado)

        }

    }, [idEditar, orden])

    const UpdateOrdenes = async (estadoValor) => {
       
        try {


            actualizarOrdenes({
                idDoc:idEditar,
               estado:estadoValor
                
            });
            setEstadoAlert(true);
            setMensajeAlert({
                mensajeAlert: 'La orden se actualizó correctamente',
                type: 'success'
            })
            setViewModal(false);

        } catch (err) {
            setEstadoAlert(true);

            let mensaje;
            switch (err.code) {
                default:
                    mensaje = "Ocurrió un error"
                    break;
            }
            setMensajeAlert({
                mensajeAlert: mensaje,
                type: 'error'
            })
        }
    }

    return (
        <>
        
        <Alert
                mensajeAlert={mensajeAlert.mensajeAlert}
                type={mensajeAlert.type}
                estadoAlert={estadoAlert}
                setEstadoAlert={setEstadoAlert}

            ></Alert>
            <Modal titulo={Nombre} estadoModal={viewModal} cambiarEstado={setViewModal} tamano={80}>
            <ContentModalOrdenes>
                <Row>
                    <Column w={40}>
                    <h5>Estado</h5>
                        <select
                            value={Estado}
                            onChange={(e) => { setEstado(e.target.value) }}

                        >
                            <option value={'pendiente'} >Pendiente</option>
                            <option value={'en proceso'}>En Proceso</option>
                            <option value={'entregado'}>Entregado</option>
        
                        </select>
                        <h5>Datos Cliente</h5>
                        <DivDatosCliente>
                            <p><span>Nombre: </span>{Nombre}</p>
                            <p><span>Correo:</span>{Correo}</p>
                            <p><span>Teléfono:</span>{Telefono}</p>
                            <p><span>Dirección:</span>{Direccion}</p>
                           
                        </DivDatosCliente>
                    </Column>
                    <Column w={40}>
                        <h5>Datos Orden</h5>
                        <DivDatosCliente>
                            <p><span>Fecha de entrega: </span>{fecha}</p>
                            <p><span>Forma de Pago:</span>{formDePago}</p>
                            <p><span>Rango Horario:</span>{rangoHorario}</p>
                           
                    
                        </DivDatosCliente>
                    </Column>
                </Row>
                <DetalleOrden>
                    <Tabla>
                        {productos.length > 0 ?
                            <>
                                <thead>
                                    <tr>
                                        <th>COD </th>
                                        <th>Cant </th>
                                        <th>Nombre </th>


                                        


                                    </tr>
                                </thead>
                                <tbody>
                                    {productos.map((productElement) => {
                                        return (
                                            <tr key={productElement.idProducto}>
                                                <td>{productElement.cod} </td>
                                                <td>{productElement.cant}</td>
                                                <td>{productElement.nombre}</td>
                                               
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </>
                            : <></>}
                    </Tabla>
                </DetalleOrden>
                <Row justifyContent={"end"} margin={"0 0 20px 0"}>
                    
                    <Column w={15} margin={0}>
                        <Button onClick={()=>{UpdateOrdenes(Estado)}}>Aceptar</Button>
                    </Column>
                </Row>
            </ContentModalOrdenes>
        </Modal>
        </>
   
    );
}

export default ModalEditarOrden;
import { db } from "./firebaseConfig";
import { collection, addDoc } from "firebase/firestore";
const crearStock = ({
    nombre,
    marca,
    tipo="aridos",
    unidad="g",
    cant,
    cantMin,
    idProveedor,
    presentacion,
    cantXUnidad,
    cantTotalxUnidad}) => {
  
        return addDoc(collection(db, "stock"), {
            nombre:nombre,
            marca:marca,
            tipo:tipo,
            unidad:unidad,
            cant:cant,
            cantMin:cantMin,
            idProveedor:idProveedor,
            presentacion:presentacion,
            cantXUnidad:cantXUnidad,
            cantTotalxUnidad:cantTotalxUnidad
            
        });
        

}
 
export default crearStock;
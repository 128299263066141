import { OrdenesStyle, ThumbOrdenes, DateContainer, DivTotales } from './style';
import BtnList from '../../componentes/btnList';
import { useState, useEffect } from 'react';
import System from '../../componentes/sistema';
import ModalEditarOrden from './modalEditarOrden';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import esLocale from 'date-fns/locale/es';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import useObtenerVentas from '../../hooks/useObtenerVentas';

// import AgregarDato from "../../firebase/agregarDato";
// import Button from "../../componentes/button";

const Ventas = () => {
  const [viewModalEditar, setViewModalEditar] = useState(false);
  const [idEditar, SetidEditar] = useState('');
  const [fecha, setFecha] = useState(new Date());
  const [ventas] = useObtenerVentas(fecha);
  const [totales, setTotales] = useState({
    TDC: 0,
    TDD: 0,
    cheque: 0,
    efectivo: 0,
    efectivoObra: 0,
    transferencia: 0,
  });

  const modalEditar = (id) => {
    SetidEditar(id);
    setViewModalEditar(true);
  };

  useEffect(() => {
    if (ventas.length > 0) {
      let TDC = 0;
      let TDD = 0;
      let cheque = 0;
      let efectivo = 0;
      let efectivoObra = 0;
      let transferencia = 0;
      ventas.forEach((element) => {
        switch (element.formaDePago) {
          case 'tarjeta de credito':
            TDC = TDC + Number(element.total);
            break;
          case 'tarjeta de debito':
            TDD = TDD + Number(element.total);
            break;
          case 'cheque':
            cheque = cheque + Number(element.total);
            break;
          case 'efectivo':
            efectivo = efectivo + Number(element.total);
            break;
          case 'efectivo cobro en obra':
            efectivoObra = efectivoObra + Number(element.total);
            break;
          case 'transferencia':
            transferencia = transferencia + Number(element.total);
            break;

          default:
            break;
        }
      });
      setTotales({
        TDC: TDC,
        TDD: TDD,
        cheque: cheque,
        efectivo: efectivo,
        efectivoObra: efectivoObra,
        transferencia: transferencia,
      });
    }
  }, [ventas]);

  return (
    <System>
      <ModalEditarOrden
        viewModal={viewModalEditar}
        setViewModal={setViewModalEditar}
        idEditar={idEditar}
      ></ModalEditarOrden>
      {/* <Button onClick={()=>{ActualizarDataOrdenes()}}>Actualizar</Button> */}
      <OrdenesStyle>
        <DateContainer>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <DatePicker value={fecha} onChange={setFecha} />
          </MuiPickersUtilsProvider>
        </DateContainer>
        <DivTotales>
          <div>
            <h5>TDC</h5>
            <span>${totales.TDC}</span>
          </div>
          <div>
            <h5>TDD</h5>
            <span>${totales.TDD}</span>
          </div>
          <div>
            <h5>Cheque</h5>
            <span>${totales.cheque}</span>
          </div>
          <div>
            <h5>Efectivo</h5>
            <span>${totales.efectivo}</span>
          </div>
          <div>
            <h5>Efectivo C. en Obra</h5>
            <span>${totales.efectivoObra}</span>
          </div>
          <div>
            <h5>Transferencia</h5>
            <span>${totales.transferencia}</span>
          </div>
        </DivTotales>
        <ThumbOrdenes>
          {ventas.length > 0 ? (
            ventas.map((orden) => {
              return (
                <BtnList
                  onClick={() => {
                    modalEditar(orden.idOrden);
                  }}
                  key={orden.idOrden}
                >
                  <span>{`${orden.fecha.toDate().getDate()}/${orden.fecha.toDate().getMonth() + 1
                    }/${orden.fecha.toDate().getFullYear()}`}</span>
                  <span>{orden.cliente.nombre}</span>
                  <span>{orden.estado}</span>
                </BtnList>
              );
            })
          ) : (
            <></>
          )}
        </ThumbOrdenes>
      </OrdenesStyle>
    </System>
  );
};

export default Ventas;

import { db } from "./firebaseConfig";
import { doc, updateDoc } from "firebase/firestore";


const desactivarProducto = async ({
    idDoc,
    estado,
   
}) => {
    const documento = doc(db, "productos", idDoc);

    return await updateDoc(documento, {
        estado:estado
    });
}

export default desactivarProducto;